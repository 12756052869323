import {Dealer} from "./dealer";

export class CouponResult {
  pincodes: any[];
  coupons: Coupon[];

  constructor() {
    this.pincodes = [];
    this.coupons = [];
  }
}

export class CouponType {
  id: number;
  typeName: string;

  constructor() {
    this.id = null;
    this.typeName = '';
  }
}

export class Coupon {
  id: string;
  cpf: string;
  type: CouponType;
  number: string;
  serie: string;
  isContested: boolean;
  createdAt: Date;
  dealership: Dealer;
  buyedAt: Date;
  totalService: number;
  totalProduct: number;
  total: number;

  constructor() {
    this.cpf = '';
    this.number = '';
    this.type = new CouponType();
    this.serie = null;
    this.buyedAt = null;
    this.totalService = null;
    this.totalProduct = null;
    this.total = null;
    this.dealership = new Dealer();
  }
}


