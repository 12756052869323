import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {EventBusService, Events} from '../../service/event-bus.service';
import {Subscription} from 'rxjs';
import {Token} from "../../model/user/token";
import {User} from "../../model/user/user";
import {Gtag} from "angular-gtag";

declare var $: any;
// declare let ga: Function;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'web';
  eventPageSingle: Subscription;
  isSinglePage = false;
  modelUser = new User();

  constructor(public router: Router,
              private eventbus: EventBusService,
              public userService: UserService,
              public gtag: Gtag) {
    super(router);
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('navegado ' + event.urlAfterRedirects);
        /*if (!this.isNullOrUndefined(this.modelUser.name)) {
          this.handlerUserInvalid();
        } else {
          setTimeout(() => {
            this.handlerUserInvalid();
          }, 1000);
        }*/
      }
    });

    this.eventPageSingle = this.eventbus.on(Events.pageSingle, (data) => this.isSinglePage = data);
    setTimeout(() => {
      super.toggleLoader(false);
    }, 1000);
    this.userService.checkAuthenticated();
    if (this.userService.isAuthenticated) {
      this.userService.getMe().subscribe(
        data => {
          this.modelUser = data as User;
          this.handlerUserInvalid();
          this.userService.updateUserData(this.modelUser);
        },
        error => {
          this.onError(error);
          localStorage.clear();
          this.userService.isAuthenticated = false;
          this.router.navigate(['/login']);
        }
      );
    } else {
      const p = ['regulamento', 'cadastro', 'recuperar'];
      let i = false;
      p.map((x) => { if (this.router.url.indexOf(x) >= 0) { i = true; } });
      if (!i) { this.router.navigate(['/login']); }
    }
  }

  ngAfterViewInit(): void {
    $(document).on('show.bs.modal', '.modal', function() {
      $(this).appendTo('body');
    });
  }

  ngOnDestroy() {
    this.eventPageSingle.unsubscribe();
  }

  handlerUserInvalid() {
    if (!this.modelUser.isValidated) {
      this.router.navigate(['/cadastro']);
    }
  }


}
