<section class="page-single page-single-white" [ngClass]="{'is-open': isPageSingle}">

  <div class="page-single-vw">
    <div class="page-single-vw-line"></div>
    <div class="page-single-vw-brand">
      <svg-icon class="svg svg-logo svg-primary" [src]="'assets/svg/logoVW.svg'"></svg-icon>
    </div>
    <div class="page-single-vw-line full"></div>
  </div>

  <div class="page-single-header">
    <a href="javascript:void('');" (click)="goBack()" class="page-single-header-btn">
      <svg-icon class="svg svg-md" [src]="'assets/svg/arrow.svg'"></svg-icon>
    </a>
    <h1 class="page-single-header-title">Cadastro</h1>
  </div>

  <div class="container pb-5 mt-5">
    <h2>Cadastre sua nota fiscal</h2>

    <div class="row my-4">
      <div class="col-12 col-lg-6">
        <ul class="list-legend">
          <li>Cada pincode gera 1 número da sorte.</li>
          <li>Limite de 3 pincodes e 3 números da sorte.</li>
          <li>Preencha os dados da(s) NF(s).</li>
          <li>Serão aceitas NFs com datas dentro do período da promoção (de 1º/11/2021 a 31/01/2022).</li>
          <li>Cada NF só poderá ser cadastrada apenas 1 vez na promoção.</li>
          <li>Toda NF cadastrada deverá obrigatoriamente estar vinculada com o CPF do usuário logado.</li>
        </ul>
      </div>
      <div class="col-12 col-lg-6">
        <ul class="list-legend">
          <li>Limite de 3 NFs da mesma concessionária e com a mesma data de compra.</li>
          <li>É possível somar as NFs durante o cadastro (limite de 3 NFs no mesmo cadastro).</li>
          <li>Diferenciar os valores de Peças e Serviços.</li>
          <li>É obrigatório em todo cadastro conter entre a(s) NF(s) valor referente a Serviço. Valores de Peças não são obrigatórios.</li>
        </ul>
      </div>
    </div>

    <div class="card-box">
      <h3 class="card-box-title"><strong>Selecione</strong> o conteúdo da sua <strong>Nota Fiscal.</strong></h3>
      <div>
        <div class="d-block pt-2 pt-lg-0 pl-1 pl-lg-4">
          <p><strong>Atenção!</strong> Você deve cadastrar TODAS as suas NOTAS FISCAIS e PINCODES referentes à promoção neste momento. A somatória das notas definirá a quantidade de números da sorte que você receberá para concorrer ao Polo 0 km.</p>
        </div>
        <div class="card-box-list" *ngIf="false">
          <a class="card-box-list-link" href="javascript:void('');" (click)="onNoteAdd(1)">Serviço</a>
          <a class="card-box-list-link" href="javascript:void('');" (click)="onNoteAdd(2)">Peças</a>
          <a class="card-box-list-link" href="javascript:void('');" (click)="onNoteAdd(3)" *ngIf="false">Serviços e Peças</a>
        </div>
      </div>
    </div>

    <div class="card-custom-secondary">
      <h3 class="card-custom-secondary-title" *ngIf="false">Total de notas fiscais cadastradas</h3>
      <div class="card-custom-secondary-box">
        <div class="card-list" style="width: 100%">

          <ng-container *ngIf="modelResult.coupons.length === 0">
            <div class="card-list-item w-100">
              <div class="card-list-panel">
                <div class="card-list-line p-4">
                  <div class="d-block text-center w-100 p-4">
                    <strong>PARTICIPAÇÃO ENCERRADA!</strong>
                  </div>
                  <!--<div class="d-block text-center w-100 p-4">
                    Nenhuma nota fiscal cadastrada.
                    Selecione acima o conteúdo da sua nota fiscal para cadastrá-la.
                  </div>-->
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="modelResult.coupons.length > 0">
            <div class="card-list-item" *ngFor="let x of modelResult.coupons">
              <div class="card-list-panel">
                <div class="card-list-line">
                  <span class="card-list-item-lbl">Tipo</span>
                  <span class="card-list-item-text">NF de {{x.type.typeName}}</span>
                </div>
                <div class="card-list-line">
                  <span class="card-list-item-lbl">Razão Social</span>
                  <span class="card-list-item-text"><strong>{{x.dealership.fantasyName}}</strong></span>
                </div>
                <div class="card-list-line">
                  <span class="card-list-item-lbl">CNPJ</span>
                  <span class="card-list-item-text">{{x.dealership.cnpj}}</span>
                </div>
                <div class="card-list-line">
                  <span class="card-list-item-lbl">Nº NF/Serie</span>
                  <span class="card-list-item-text">{{x.number}} / {{x.serie}}</span>
                </div>
                <div class="card-list-line">
                  <span class="card-list-item-lbl">Data de emissão</span>
                  <span class="card-list-item-text">{{x.buyedAt | date : 'dd/MM/yyyy'}}</span>
                </div>
                <div class="card-list-line">
                  <div class="card-list-item-highlight">{{x.total | currency:'BRL'}}</div>
                </div>
              </div>
              <div class="card-list-footer">
                <a href="javascript:void('');" (click)="onRemoveNote(x)">x Excluir</a>
              </div>
            </div>
          </ng-container>

        </div>
        <div class="card-resume" *ngIf="false">
          <div class="card-resume-info">
            Total das notas fiscais
            <strong>{{txtTotal | currency: 'BRL'}}</strong>
          </div>
          <span>
            PARTICIPAÇÃO ENCERRADA!
          </span>
          <a href="javascript:$('#modalPincode').modal('show'); void('');" *ngIf="isServiceTotal"
             class="btn btn-primary btn-block btn-xxl">CONFIRMAR</a>
        </div>
      </div>
    </div>

  </div>

</section>


<!-- Modal -->
<div class="modal fade modal-custom" id="modalCoupon" tabindex="-1" role="dialog"
     aria-labelledby="modalCouponTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-blocks">
        <img src="assets/images/block-close.png" alt="Fechar" class="modal-blocks modal-blocks-close" data-dismiss="modal" aria-label="Close" />
      </div>
      <div class="modal-content-box">
        <div class="modal-body">
          <h2 class="modal-body-title">NF de {{this.modelDetail.type.typeName}}</h2>
          <fieldset class="form-primary" *ngIf="true">
            <h2 class="modal-body-title">PARTICIPAÇÃO ENCERRADA!</h2>
          </fieldset>
          <fieldset class="form-primary" *ngIf="false">
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="input-material">
                  <input type="text" required="required" name="promoLCouponCPF" maxlength="20"
                         [(ngModel)]="modelDetail.cpf"
                         [clearIfNotMatch]="true"
                         [mask]="'000.000.000-00'"
                         (blur)="onClearModel($event, 'cpf')"
                         [dropSpecialCharacters]="false" />
                  <label>CPF</label>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="additional-information">
                  <svg-icon class="svg svg-lg" [src]="'assets/svg/circle-interrogation.svg'"></svg-icon>
                  <span class="additional-information-text">Descubra onde encontrar as informação na nota ou cupom</span>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-material">
                  <input type="text" required="required" name="promoCouponCNPJ" id="promoCouponCNPJ" maxlength="30"
                         [(ngModel)]="modelDetail.dealership.cnpj"
                         [clearIfNotMatch]="true"
                         [mask]="'00.000.000/0000-00'"
                         (blur)="onClearModel($event, 'dealership.cnpj')"
                         (keyup)="onAutoCompleteCnpj($event)"
                         [dropSpecialCharacters]="false" />
                  <label>CNPJ</label>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-material">
                  <input type="text" required="required" name="promoCouponcompany_name" id="promoCouponcompany_name" maxlength="250"
                         [(ngModel)]="modelDetail.dealership.fantasyName"
                         [disabled]="true"
                  />
                  <label class="disabled">Razão Social da Concessionária</label>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-material">
                  <input type="text" required="required" name="promoCouponnumber" id="promoCouponnumber" maxlength="9" appOnlyNumber
                         [(ngModel)]="modelDetail.number"
                  />
                  <label>Número da Nota/Cupom</label>
                </div>
              </div>
              <div class="col-12 col-lg-2">
                <div class="input-material">
                  <input type="text" required="required" name="promoCouponserie" id="promoCouponserie" maxlength="3"
                         [(ngModel)]="modelDetail.serie"
                  />
                  <label>Serie</label>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="input-material">
                  <input name="promoCouponissue_date" id="promoCouponissue_date" type="text" required="required" maxlength="11"
                         [(ngModel)]="modelDetail.buyedAt"
                         [clearIfNotMatch]="true"
                         (blur)="onClearModel($event, 'buyedAt')"
                         [mask]="'d0/M0/0000'" [dropSpecialCharacters]="false" />
                  <label for="promoCouponissue_date">Data da emissão</label>
                </div>
              </div>
              <ng-container *ngIf="this.modelDetail.type.id == 3">
                <div class="col-12 col-lg-4">
                  <div class="input-material">
                    <input type="text" currencyMask required="required" name="promoCouponservice_value" id="promoCouponservice_value" maxlength="12"
                           [(ngModel)]="modelDetail.totalService" />
                    <label>Valor de serviço</label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="input-material">
                    <input type="text" currencyMask required="required" name="promoCouponproducts_value" id="promoCouponproducts_value" maxlength="12"
                           [(ngModel)]="modelDetail.totalProduct" />
                    <label>Valor de produtos</label>
                  </div>
                </div>
              </ng-container>
              <div class="col-12 col-lg-4">
                <div class="input-material">
                  <input type="text" currencyMask required="required" name="promoCouponnote_value" id="promoCouponnote_value" maxlength="12"
                         [(ngModel)]="modelDetail.total" />
                  <label>Valor da nota</label>
                </div>
              </div>
              <ng-container *ngIf="this.modelDetail.type.id < 3">
                <div class="col-12 col-lg-8">
                  <a href="javascript:void('');" class="btn btn-block btn-primary py-2 px-5" (click)="onSave()">Adicionar</a>
                </div>
              </ng-container>

            </div>
            <div class="d-block mb-1 text-center" *ngIf="this.modelDetail.type.id == 3">
              <a href="javascript:void('');" class="btn btn-xxl btn-primary px-5" (click)="onSave()">Adicionar</a>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="modal-content-footer">
        <img src="assets/images/modal-footer.png" alt="VW" />
      </div>
    </div>
  </div>
</div>


<!-- Modal PINCODE -->
<div class="modal fade modal-custom" id="modalPincode" tabindex="-1" role="dialog"
     aria-labelledby="modalPincodeTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-blocks">
        <img src="assets/images/block-close.png" alt="Fechar" class="modal-blocks modal-blocks-close"
             data-dismiss="modal" aria-label="Close"/>
      </div>
      <div class="modal-content-box">
        <!--<div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">[X]</button>
        </div>-->
        <div class="modal-body" *ngIf="true">
          <h2 class="modal-body-title">PARTICIPAÇÃO ENCERRADA!</h2>
        </div>
        <div class="modal-body" *ngIf="false">
          <h2 class="modal-body-title">Insira seu pincode</h2>
          <p>Encontre seu pincode no verso do cartão que você recebeu dentro da embalagem da sua caixa organizadora. Lembre-se de guardar seu cartão até o fim da promoção!</p>
          <fieldset class="form-primary">
            <div class="input-material" *ngFor="let x of listPincode; let i = index;">
              <input type="text" required="required" class="text-uppercase" [maxLength]="15" [name]="'promoPincode-' + i"
                     [(ngModel)]="modelResult.pincodes[i]"/>
              <label>PINCODE</label>
            </div>
            <div class="d-block mb-4">
              <a href="javascript:void('');" (click)="onConfirmCoupons()" class="btn btn-block btn-primary">ENVIAR</a>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="modal-content-footer">
        <img src="assets/images/modal-footer.png" alt="VW"/>
      </div>
    </div>
  </div>
</div>
