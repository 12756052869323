import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class RankingService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  getRankingUser(dn: string) {
    const url = `${this.BASE_URL}/ranking/user/${dn}`;
    return this.http.get(url);
  }

  getRankingAll(dn: string, month: string) {
    const url = `${this.BASE_URL}/ranking/all/${dn}/${month}`;
    return this.http.get(url);
  }

  getPointsMonth(dn: string, month: string) {
    const url = `${this.BASE_URL}/ranking/partner/${month}/${dn}`;
    return this.http.get(url);
  }

  getRankingCategory(period: string, month: string, dn: string) {
    const url = `${this.BASE_URL}/ranking/category/${period}/${month}/${dn}`;
    return this.http.get(url);
  }

  getRankingCategoryDetail(month: string, dn: string) {
    const url = `${this.BASE_URL}/ranking/category/detail/${month}/${dn}`;
    return this.http.get(url);
  }

  getRankingLine(month: string, dn: string) {
    const url = `${this.BASE_URL}/ranking/line/${month}/${dn}`;
    return this.http.get(url);
  }

  getAutoComplete(keyword: string) {
    const url = `${this.BASE_URL}/ranking/user/autocomplete?keyword=${keyword}`;
    return this.http.get(url);
  }

  getExcel(e: string) {
    const url = `${this.BASE_URL}/ranking/all-excel/${e}`;
    return this.http.get(url);
  }

}
