export class Dealer {
  id: string;
  cnpj: string;
  fantasyName: string;

  constructor() {
    this.cnpj = '';
    this.fantasyName = '';
  }
}
