<div class="modal fade modal-custom" id="modalMyGrades" tabindex="-1" role="dialog"
     aria-labelledby="modalLoginTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 666px" role="document">
    <div class="modal-content">
      <div class="modal-blocks">
        <img src="assets/images/block-close.png" alt="Fechar" class="modal-blocks modal-blocks-close"
             data-dismiss="modal" aria-label="Close"/>
      </div>
      <div class="modal-content-box">

        <div class="modal-body pb-0">
          <h2 class="modal-body-title">Minhas notas</h2>
        </div>

        <div class="card-list card-list-secondary">
          <div class="d-block py-5 w-100 text-center" *ngIf="arrList.length === 0">Nenhum registro encontrado.</div>
          <div class="card-list-item" *ngFor="let x of arrList">
            <div class="card-list-panel">
              <div class="card-list-line">
                <span class="card-list-item-lbl">Tipo</span>
                <span class="card-list-item-text">NF de {{x.type.typeName}}</span>
              </div>
              <div class="card-list-line">
                <span class="card-list-item-lbl">Razão Social</span>
                <span class="card-list-item-text"><strong>{{x.dealership.fantasyName}}</strong></span>
              </div>
              <div class="card-list-line">
                <span class="card-list-item-lbl">CNPJ</span>
                <span class="card-list-item-text">{{x.dealership.cnpj}}</span>
              </div>
              <div class="card-list-line">
                <span class="card-list-item-lbl">Nº NF / Serie</span>
                <span class="card-list-item-text">{{x.number}} / {{x.serie}}</span>
              </div>
              <div class="card-list-line">
                <span class="card-list-item-lbl">Data de emissão</span>
                <span class="card-list-item-text">{{x.buyedAt | date : 'dd/MM/yyyy'}}</span>
              </div>
              <div class="card-list-line">
                <div class="card-list-item-highlight">{{x.total | currency:'BRL'}}</div>
              </div>
            </div>
          </div>

          <!--<ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" id="slickSlider">
            <div ngxSlickItem *ngFor="let x of modelResult">
            </div>
          </ngx-slick-carousel>-->

        </div>
        <div class="modal-body pt-0">
          <fieldset class="form-primary">
            <div class="group">
              <div class="row mt-5 mb-4">
                <div class="col-12">
                  <a href="javascript:$('#modalMyGrades').modal('hide'); void('');" (click)="this.isSinglePage = true; goToInto('/cadastrar-cupom', true)"
                     *ngIf="false"
                     class="btn btn-block btn-primary">Cadastrar nota fiscal</a>
                </div>
              </div>
            </div>
          </fieldset>

        </div>

      </div>
      <div class="modal-content-footer">
        <img src="assets/images/modal-footer.png" alt="VW"/>
      </div>
    </div>
  </div>
</div>
