import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ContactService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  setContact(model) {
    const url = `${this.BASE_URL}/contact/user`;
    return this.http.post(url, model);
  }

}
