export class PartnerList {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  previousPage: boolean;
  nextPage: boolean;
  results: PartnerListResult[];

  constructor() {
    this.results = [];
  }
}

export class PartnerFilter {
  month: string;
  dn: string;
  category: string;
  partner: string;
  page: number;
}

export class PartnerListResult {
  dn: number;
  category: string;
  dealer: string;
  fantasy: string;
  date: Date;
  objective: number;
  position: number;
  points: number;

  constructor() {
  }
}

export class PartnerDashboard {
  date: string;
  total_pontos: number;

  constructor() {
  }
}
