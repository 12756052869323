import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {EmitEvent, EventBusService, Events} from '../../service/event-bus.service';
import {UserService} from '../../service/user.service';
import {Coupon, CouponResult} from '../../model/coupon/coupon';
import * as moment from 'moment';
import {CouponService} from '../../service/coupon.service';
import {Dealer} from "../../model/coupon/dealer";
import {User} from "../../model/user/user";

declare var $: any;

@Component({
  selector: 'app-register-coupon',
  templateUrl: './register-coupon.component.html',
  styleUrls: ['./register-coupon.component.scss']
})
export class RegisterCouponComponent extends BaseComponent implements OnInit, OnDestroy {
  model: User = new User();
  modelResult: CouponResult = new CouponResult();
  modelDetail: Coupon = new Coupon();
  isPageSingle = false;
  isServiceTotal = false;
  txtTotal = 0;
  pincode = 0;
  listPincode: any[] = [];

  constructor(public router: Router,
              private eventbus: EventBusService,
              public userService: UserService,
              public couponService: CouponService) {
    super(router);
  }

  ngOnInit(): void {
    this.eventbus.emit(new EmitEvent(Events.pageSingle, true));
    setTimeout(() => {
      this.toggleLoader(false);
      this.isPageSingle = true;
    }, 500);
    this.userService.user.subscribe({
      next: data => {
        this.model = data as User;
        if (this.isNullOrUndefined(this.model.cpf)) { return; }

      }
    });
  }

  ngOnDestroy(): void {
    const a = $('#modalCoupon');
    const b = $('#modalPincode');
    if (a[0]) { a.remove(); }
    if (b[0]) { b.remove(); }
    this.eventbus.emit(new EmitEvent(Events.pageSingle, false));
  }

  goBack() {
    this.isPageSingle = false;
    this.toggleLoader(true);
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000);
  }

  onClearModel(event: FocusEvent, str: string) {
    setTimeout(() => {
      const a = $(event.target).val();
      if (super.isNullOrUndefined(a)) {
        this.modelDetail[str] = '';
      }
    });
  }

  onNoteAdd(s: number) {
    this.modelDetail.type = this.handlerCouponType(s);
    $('#modalCoupon').modal('show');
  }

  onSave() {
    return; // ENCERRAMENTO
    if (this.isNullOrUndefined(this.modelDetail.type.id)) {
      return;
    }
    const arrValidateFields = [
      {value: this.modelDetail.cpf, text: 'CPF*, <br />'},
      {value: this.modelDetail.dealership?.cnpj, text: 'CNPJ*, <br />'},
      {value: this.modelDetail.number, text: 'Número da Nota/Cupom*, <br />'},
      {value: this.modelDetail.serie, text: 'Serie*, <br />'},
      {value: this.modelDetail.buyedAt, text: 'Data da emissão*, <br />'},
      {value: this.modelDetail.total, text: 'Valor da nota*, <br />'},
    ];
    if (this.modelDetail.type.id === 3) {
      // @ts-ignore
      arrValidateFields.push({value: this.modelDetail.totalService, text: 'Valor de serviço*, <br />'});
      // @ts-ignore
      arrValidateFields.push({value: this.modelDetail.totalProduct, text: 'Valor de produtos*, <br />'});
    }
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos obrigatórios: <br><br> ${stringError}`, 'warning');
      return;
    }
    if (!this.isCPFValid(this.modelDetail.cpf)) {
      return;
    }
    if (this.modelDetail.cpf !== this.model.cpf) {
      this.showMessage('Atenção', `O CPF da Nota Fiscal não pode ser diferente do usuário cadastrado.`, 'warning');
      return;
    }
    if (!super.isCNPJValid(this.modelDetail.dealership.cnpj)) {
      return;
    }
    if (this.isNullOrUndefined(this.modelDetail.dealership?.fantasyName)) {
      this.showMessage('Atenção', `Concessionária não participante, verifique se o CNPJ foi digitado corretamente.`, 'warning');
      return;
    }

    // {value: this.modelDetail.dealership?.fantasyName, text: 'Razão Social*, <br />'},

    if (!super.dateOfPromotionDateValidate(this.modelDetail.buyedAt)) {
      return;
    } else {
      if (!(this.modelDetail.buyedAt instanceof Date)) {
        console.log(this.modelDetail.buyedAt);
        this.modelDetail.buyedAt = moment(this.modelDetail.buyedAt, 'DD/MM/YYYY').toDate();
      }
      // console.log(this.modelDetail.buyedAt);
    }

    if (this.modelDetail.type.id === 3
      && (this.modelDetail.totalService === 0 || this.modelDetail.totalProduct === 0 || this.modelDetail.total === 0)
    ) {
      this.showMessage('Atenção', `Para NF de ${this.modelDetail.type.typeName} informe os valores de serviço, produtos e valor total da nota preenchidos.`, 'warning');
      return;
    } else {
      if (this.modelDetail.total === 0) {
        this.showMessage('Atenção', `Para NF de ${this.modelDetail.type.typeName} informe o valor da nota.`, 'warning');
        return;
      }
    }

    this.handlerCouponAdd();
  }

  onAutoCompleteCnpj(event: KeyboardEvent) {
    const a = $(event.target).val();
    if (a.length > 17) {
      const d = new Dealer();
      d.cnpj = this.modelDetail.dealership.cnpj;
      d.fantasyName = this.modelDetail.dealership.fantasyName;
      this.couponService.checkDealer(d).subscribe({
        next: data => {
          this.modelDetail.dealership = data as Dealer;
        }, error: err => {
          this.modelDetail.dealership.fantasyName = undefined;
          this.showMessage('Atenção', `Concessionária não participante, verifique se o CNPJ foi digitado corretamente.`, 'warning');
        }
      });
    }
  }

  handlerCouponAdd() {
    return; // ENCERRAMENTO
    const isExistCoupon = this.modelResult.coupons.filter((x) => {
      return (x.number === this.modelDetail.number)
        && (x.serie === this.modelDetail.serie)
        && (x.type === this.modelDetail.type)
        && (moment(x.buyedAt).isSame(this.modelDetail.buyedAt))
        && (x.dealership.cnpj === this.modelDetail.dealership.cnpj)
        && (x.cpf === this.modelDetail.cpf);
    });
    if (isExistCoupon[0]) {
      this.showMessage('Atenção', `Não é possível cadastrar novamente a Nota/Cupom.`, 'warning');
      return;
    }
    this.modelResult.coupons.push(this.modelDetail);
    this.modelDetail = new Coupon();
    $('#modalCoupon').modal('hide');
    this.handlerCouponServicesTotal();
    // this.showMessage('Parabéns', `Cadastro realizado com sucesso.`, 'success');
  }

  handlerCouponServicesTotal() {
    this.pincode = 0;
    this.listPincode = [];
    let t = 0;
    let r = 0;
    this.modelResult.coupons.map((x) => {
      t = t + x.total;
      if (x.type.id === 1) {
        r = r + x.total;
      } else if (x.type.id === 3) {
        r = r + x.totalService;
      }
      if (x.type.id < 3) {
        x.totalProduct = 0;
        x.totalService = 0;
      }
    });
    this.txtTotal = t;
    if (r > 0 && t >= 700) {
      this.isServiceTotal = true;
    } else {
      this.isServiceTotal = false;
    }

    // PINCODES
    if (r > 0 && (t >= 700 && t < 1400)) {
      this.pincode = 1;
      this.listPincode.push('');
    } else if (r > 0 && (t >= 1400 && t < 2100)) {
      this.listPincode.push('');
      this.listPincode.push('');
      this.pincode = 2;
    } else if (r > 0 && t >= 2100) {
      this.listPincode.push('');
      this.listPincode.push('');
      this.listPincode.push('');
      this.pincode = 3;
    }
    // arrPincode
    return r;
  }

  onConfirmCoupons() {
    return; // ENCERRAMENTO
    let count = 0;
    this.modelResult.pincodes.map((x) => {
      if (!this.isNullOrUndefined(x.trim())) { count++; }
    });
    if (count === 0) {
      this.showMessage('Atenção', `Informe o pincode para prosseguir.`, 'warning');
      return;
    }
    /*if (this.isNullOrUndefined(this.modelResult.pincodes) || count > 0 || this.listPincode.length > this.modelResult.pincodes.length) {
      this.showMessage('Atenção', `Informe o pincode para prosseguir.`, 'warning');
      return;
    }*/
    this.confirmMessage('Atenção', 'Tem certeza de que deseja confirmar o envio? Lembre-se de que as notas fiscais cadastradas não poderão ser usadas novamente!', () => {
      this.setCouponAdd();
    });
  }

  setCouponAdd() {
    return; // ENCERRAMENTO
    this.couponService.createCoupon(this.modelResult).subscribe({
      next: data => {
        $('#modalPincode').modal('hide');
        $('#modalCoupon').modal('hide');
        this.listPincode = [];
        this.modelResult = new CouponResult();
        this.modelDetail = new Coupon();
        this.showMessage('Parabéns', `Seu cadastro foi concluído com sucesso. Confira seus números da sorte.`, 'success');
        this.router.navigate(['/']);
        setTimeout(() => {
          $('.modal-backdrop.show').remove();
          //  $('#modalLuckyNumber').modal('show');
          this.eventbus.emit(new EmitEvent(Events.openModal, 'numbers'));
        }, 1000);
      },
      error: (error) => {
        super.onError(error);
      }
    });
  }

  onRemoveNote(x: Coupon) {
    this.confirmMessage('Atenção', 'Tem certeza que deseja excluir essa Nota Fiscal?', () => {
      const index = this.modelResult.coupons.indexOf(x);
      if (index > -1) {
        this.modelResult.coupons.splice(index, 1);
        setTimeout(() => {
          this.handlerCouponServicesTotal();
        }, 500);
      }
    });
  }
}
