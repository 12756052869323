import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from "rxjs";
import {User} from "../model/user/user";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ServiceBase {
  isAuthenticated = false;

  private userDataSource = new BehaviorSubject<User>(new User());
  user = this.userDataSource.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  checkAuthenticated() {
    const token = localStorage.getItem('token') as string;
    if (token != null) {
      this.isAuthenticated = true;
    }
    return this.isAuthenticated;
  }

  validate(id) {
    const url = `${this.BASE_URL}/user/validate-reset-token/${id}`;
    return this.http.get(url);
  }

  updateUserData(user) {
    this.userDataSource.next(user);
  }

  getCep(i) {
    const url = `https://viacep.com.br/ws/${i}/json`;
    return this.http.get(url);
  }

  getMe() {
    const url = `${this.BASE_URL}/user/me`;
    return this.http.get(url);
  }

  reset(data) {
    const url = `${this.BASE_URL}/user/reset-password`;
    return this.http.post(url, data);
  }

  changePassword(model) {
    const url = `${this.BASE_URL}/user/update-password`;
    return this.http.put(url, model);
  }

  getState() {
    const url = `${this.BASE_URL}/common/states`;
    return this.http.get(url);
  }

  getCity(p: string) {
    const url = `${this.BASE_URL}/common/cities/${p}`;
    return this.http.get(url);
  }

  getAuthenticate(model) {
    const url = `${this.BASE_URL}/user/authenticate`;
    return this.http.post(url, model);
  }

  forgot(model) {
    const url = `${this.BASE_URL}/user/forgot`;
    return this.http.post(url, model);
  }

  createUser(model) {
    const url = `${this.BASE_URL}/user`;
    return this.http.post(url, model);
  }

  updateUser(model) {
    const url = `${this.BASE_URL}/user`;
    return this.http.put(url, model);
  }


}
