<section class="page-single" [ngClass]="{'is-open': isPageSingle}">

  <div class="page-single-vw">
    <div class="page-single-vw-line-dark"></div>
    <div class="page-single-vw-brand">
      <svg-icon class="svg svg-logo svg-primary" [src]="'assets/svg/logoVW.svg'"></svg-icon>
    </div>
    <div class="page-single-vw-line-dark full"></div>
  </div>

  <div class="page-single-header">
    <div class="page-single-header-content">
      <a href="javascript:void('');" (click)="goBack()" class="page-single-header-btn-custom">
        <svg-icon class="svg svg-md svg-primary" [src]="'assets/svg/arrow.svg'"></svg-icon>
      </a>
      <h1 class="page-single-header-title">{{model.isValidated?'Meus Dados':'Cadastro'}}</h1>
    </div>
  </div>

  <div class="container pb-5">
    <form action="#" autocomplete="off">
      <h2 class="text-primary">Dados Pessoais</h2>
      <div class="card-primary">
        <fieldset class="form-primary">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="input-material">
                <input required="required"
                       name="promoCpf" id="promoCpf" type="text" maxlength="250"
                       [clearIfNotMatch]="true"
                       [readOnly]="model.isValidated"
                       [disabled]="model.isValidated"
                       (blur)="onClearModel($event, 'cpf')"
                       [(ngModel)]="model.cpf"
                       [mask]="'000.000.000-00'"
                       [dropSpecialCharacters]="false"/>
                <label [ngClass]="{'disabled' : !isNullOrUndefined(getUser())}">CPF</label>
              </div>
            </div>
            <div class="col-12 col-lg-8">
              <div class="input-material">
                <input name="promoName" id="promoName" type="text" maxlength="250" [(ngModel)]="model.name"
                       [disabled]="model.profile === 'partner'"
                       required="required"/>
                <label for="promoName" [ngClass]="{'disabled' : model.profile === 'partner'}">Nome</label>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="input-material">
                <input *ngIf="isNullOrUndefined(getUser())"
                       name="promoBirthdate" id="promoBirthdate" type="text" maxlength="250" required="required"
                       [clearIfNotMatch]="true"
                       (blur)="onClearModel($event, 'birthday')"
                       [(ngModel)]="model.birthday" [mask]="'d0/M0/0000'" [dropSpecialCharacters]="false"/>
                <input *ngIf="!isNullOrUndefined(getUser())"
                       name="promoBirthdate" id="promoBirthdate" type="text" maxlength="250" required="required"
                       [clearIfNotMatch]="true"
                       (blur)="this.model.birthday = this.modelBirthday; onClearModel($event, 'birthday')"
                       [(ngModel)]="modelBirthday" [mask]="'d0/M0/0000'" [dropSpecialCharacters]="false"/>

                <label for="promoBirthdate">Data de nascimento</label>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="input-material">
                <div class="input-material-option">
                  <div class="custom-control custom-radio">
                    <input type="radio" id="promoGenderM" name="promoGender" class="custom-control-input"
                           [checked]="model.sex === 'm'"
                           (change)="onChangeGender('m')"/>
                    <label class="custom-control-label" for="promoGenderM">M</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="promoGenderF" name="promoGender" class="custom-control-input"
                           [checked]="model.sex === 'f'"
                           (change)="onChangeGender('f')"/>
                    <label class="custom-control-label" for="promoGenderF">F</label>
                  </div>
                </div>
                <label class="disabled">Sexo</label>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="input-material">
                <input name="promoPhone" id="promoPhone" type="text" maxlength="250" required="required"
                       [(ngModel)]="model.phone" [mask]="'(00) 0000-0000||(00) 00000-0000'"
                       [dropSpecialCharacters]="false"
                       [clearIfNotMatch]="true"
                       (blur)="onClearModel($event, 'phone')"
                />
                <label for="promoPhone">Telefone</label>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="input-material">
                <input name="promoCellphone" id="promoCellphone" type="text" required="required" maxlength="250"
                       [(ngModel)]="model.cellPhone" [mask]="'(00) 0000-0000||(00) 00000-0000'"
                       [dropSpecialCharacters]="false"
                       [clearIfNotMatch]="true"
                       (blur)="onClearModel($event, 'cellPhone')"
                />
                <label for="promoCellphone">Celular</label>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="input-material">
                <input name="promoEmail" id="promoEmail" type="text" class="form-control" required="required"
                       maxlength="250"
                       [(ngModel)]="model.email"/>
                <label for="promoEmail">E-mail</label>
              </div>
            </div>
            <div class="col-12 col-lg-6" *ngIf="isNullOrUndefined(getUser())">
              <div class="input-material">
                <input name="promoEmailConfirm" id="promoEmailConfirm" type="text" class="form-control" maxlength="250"
                       required="required"
                       [(ngModel)]="promoEmailConfirm"/>
                <label for="promoEmailConfirm">Confirmar E-mail</label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <h2 class="text-primary">Endereço</h2>
      <div class="card-primary">
        <fieldset class="form-primary">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="input-material">
                <input name="promoZipcode" id="promoZipcode" type="text" class="form-control" required="required"
                       maxlength="250"
                       [(ngModel)]="model.zipcode" [mask]="'00000-000'" [dropSpecialCharacters]="false"
                       [clearIfNotMatch]="true"
                       (blur)="onClearModel($event, 'zipcode')"
                       (keyup)="onZipCodeAutoComplete($event)"
                />
                <label for="promoZipcode">CEP</label>
              </div>
            </div>
            <div class="form-group col-12 col-lg-6">
              <div class="input-material">
                <input name="promoAddress" id="promoAddress" type="text" class="form-control" required="required"
                       maxlength="250"
                       [(ngModel)]="model.address"/>
                <label for="promoAddress">Endereço</label>
              </div>
            </div>
            <div class="form-group col-12 col-lg-3">
              <div class="input-material">
                <input name="promoNumber" id="promoNumber" type="text" class="form-control" required="required"
                       maxlength="250"
                       [(ngModel)]="model.number"/>
                <label for="promoNumber">Nº</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12 col-lg-3">
              <div class="input-material">
                <input name="promoComplement" id="promoComplement" type="text" class="form-control" required="required"
                       maxlength="250"
                       [(ngModel)]="model.complement"/>
                <label for="promoComplement">Complemento</label>
              </div>
            </div>
            <div class="form-group col-12 col-lg-3">
              <div class="input-material">
                <!--<input name="promoState" id="promoState" type="text" class="form-control" required="required" maxlength="250"
                       [(ngModel)]="modelUser.state_id"/>-->
                <select name="promoState" id="promoState" class="form-control" [(ngModel)]="selectedState"
                        (ngModelChange)="onCity(selectedState)">
                  <option [ngValue]="undefined">Selecione</option>
                  <option *ngFor="let s of listState" [ngValue]="s.id">{{s.name}}</option>
                </select>
                <label for="promoState">Estado</label>
              </div>
            </div>
            <div class="form-group col-12 col-lg-3">
              <div class="input-material">
                <!--<input name="promoCity" id="promoCity" type="text" class="form-control" required="required" maxlength="250"
                       [(ngModel)]="modelUser.city_id"/>-->
                <select name="promoCity" id="promoCity" class="form-control" [(ngModel)]="selectedCity"
                        (ngModelChange)="onCitySelect(selectedCity)">
                  <option [ngValue]="undefined">Selecione</option>
                  <option *ngFor="let c of listCity" [ngValue]="c.name">{{c.name}}</option>
                </select>
                <label for="promoCity">Cidade</label>
              </div>
            </div>
            <div class="form-group col-12 col-lg-3">
              <div class="input-material">
                <input name="promoDistrict" id="promoDistrict" type="text" class="form-control" required="required"
                       maxlength="250"
                       [(ngModel)]="model.district"/>
                <label for="promoDistrict">Bairro</label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <ng-container *ngIf="model.isValidated">
        <h2 class="text-primary">Acesso</h2>
        <div class="card-primary pb-4">
          <fieldset class="form-primary">
            <div class="row">
              <div class="form-group col-12 col-lg-8">
                <p>Clique no botão ao lado para alterar sua senha.</p>
              </div>
              <div class="form-group col-12 col-lg-4">
                <a href="javascript:void('');" data-toggle="modal" data-target="#modalChangePassword"
                   class="btn btn-block btn-primary">Mudar senha</a>
              </div>
            </div>
          </fieldset>
        </div>
      </ng-container>

      <ng-container *ngIf="!model.isValidated">
        <h2 class="text-primary">Acesso</h2>
        <div class="card-primary">
          <fieldset class="form-primary">
            <div class="row">
              <div class="form-group col-12 col-lg-6">
                <div class="input-material">
                  <input name="promoPassword" id="promoPassword" type="password" class="form-control" maxlength="100"
                         [(ngModel)]="model.password"/>
                  <label for="promoPassword">Senha</label>
                </div>
              </div>
              <div class="form-group col-12 col-lg-6">
                <div class="input-material">
                  <input name="promoPasswordConfirm" id="promoPasswordConfirm" type="password" class="form-control"
                         maxlength="100"
                         [(ngModel)]="model.passwordConfirm"/>
                  <label for="promoPasswordConfirm">Confirmar senha</label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </ng-container>

      <div class="row" *ngIf="!model.isValidated">
        <!--<div class="col-12 col-lg-4">
          <div class="form-group">
            <recaptcha
              name="recaptcha"
              [(ngModel)]="myRecaptcha"
              (scriptLoad)="onScriptLoad()"
              (scriptError)="onScriptError()"
            ></recaptcha>
          </div>
        </div>-->
        <div class="col-12 col-lg-9 my-4 my-lg-0 pl-3 pl-lg-5 pl-xl-0">
          <div class="custom-control custom-control-white custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="promoAcceptTerms"
                   [checked]="model.isAccept"
                   (change)="model.isAccept = !model.isAccept"/>
            <label class="custom-control-label text-primary" for="promoAcceptTerms">
              Li e aceito os termos do <a href="/regulamento" class="text-primary" target="_blank">Regulamento</a>
            </label>
          </div>
          <div class="custom-control custom-control-white custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="promoReceiveInfo"
                   [checked]="model.isReceiveInfo"
                   (change)="model.isReceiveInfo = !model.isReceiveInfo"/>
            <label class="custom-control-label text-primary" for="promoReceiveInfo">
              Aceito receber informações sobre a campanha
            </label>
          </div>
          <div class="custom-control custom-control-white custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="promoReceiveLgpd"
                   [checked]="model.isAcceptLgpd"
                   (change)="model.isAcceptLgpd = !model.isAcceptLgpd"/>
            <label class="custom-control-label text-primary" for="promoReceiveLgpd">
              Li e aceito os termos da LGPD
            </label>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <a href="javascript:void('');" (click)="onSave()" class="btn btn-xl btn-block btn-secondary">Enviar</a>
        </div>
      </div>

      <div class="row" *ngIf="model.isValidated">
        <div class="col-12 mt-5">
          <a href="javascript:void('');" (click)="onSave()" class="btn btn-xl btn-block btn-secondary">Enviar</a>
        </div>
      </div>

    </form>
  </div>

</section>




<div class="modal fade modal-custom" id="modalChangePassword" tabindex="-1" role="dialog"
     aria-labelledby="modalChangePasswordTitle" aria-hidden="true" *ngIf="!isNullOrUndefined(getUser())">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-blocks">
        <img src="assets/images/block-close.png" alt="Fechar" class="modal-blocks modal-blocks-close"
             data-dismiss="modal" aria-label="Close"/>
      </div>
      <div class="modal-content-box">
        <div class="modal-body">
          <h2 class="modal-body-title">Nova senha</h2>
          <fieldset class="form-primary">
            <div class="input-material">
              <input type="password" required="required"
                     [(ngModel)]="modelChangePassword.actualPassword"
                     id="current-password"
                     name="password"
                     maxlength="50"/>
              <label>Senha atual</label>
              <div href="javascript:void(0)" class="btn-password-view" (click)="handlerViewPassword('current-password')">
                <svg-icon
                  [src]="!handlerViewPasswordIcon('current-password') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
              </div>
            </div>
            <div class="input-material">
              <input type="password" required="required"
                     [(ngModel)]="modelChangePassword.newPassword"
                     id="new-password"
                     name="password"
                     maxlength="50"/>
              <label>Nova Senha</label>
              <div href="javascript:void(0)" class="btn-password-view" (click)="handlerViewPassword('new-password')">
                <svg-icon
                  [src]="!handlerViewPasswordIcon('new-password') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
              </div>
            </div>
            <div class="input-material" style="margin-bottom: 8px">
              <input type="password" required="required"
                     [(ngModel)]="modelChangePassword.confirmNewPassword"
                     id="confirm-password"
                     name="password"
                     maxlength="50"/>
              <label>Confirmar Nova Senha</label>
              <div href="javascript:void(0)" class="btn-password-view" (click)="handlerViewPassword('confirm-password')">
                <svg-icon
                  [src]="!handlerViewPasswordIcon('confirm-password') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
              </div>
            </div>
            <!--<div class="group text-right">
              <a href="javascript:void('');" class="form-link"
                 (click)="isLoginPanel = true">Voltar</a>
            </div>-->
            <div class="group">
              <div class="row mt-5 mb-4">
                <div class="col-12">
                  <a href="javascript:void('');"
                     class="btn btn-block btn-primary" (click)="onUpdatePassword()">Atualizar</a>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="modal-content-footer">
        <img src="assets/images/modal-footer.png" alt="VW"/>
      </div>
    </div>
  </div>
</div>
