import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from "rxjs/operators";
import {environment} from "../../environments/environment";

declare var $: any;

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {


  requests = 0;
  // ARRAY DE URL DE SERVIÇOS QUE NAO EXIBE LOAD EX.: AUTOCOMPLETE, GOOGLEMAPS...
  arrNoLoader = [
    'assets/',
    'states/',
    'cities/',
    '/json',
    '/me',
    'check-dealer',
    'lucky-numbers',
    'coupon/me',
    'autocomplete?keyword='
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests = this.requests + 1;
    let countLoaderBlock = 0;
    this.arrNoLoader.map((e, i) => {
      if (req.url.indexOf(e) >= 0) {
        countLoaderBlock++;
      }
    });
    if (countLoaderBlock === 0) { this.toggleLoader(true); }

    if (req.url.indexOf(environment.base_url) <= -1) {
      return next.handle(req).pipe(
        finalize(() => {
          this.requests = this.requests - 1;
          if (this.requests === 0) {
            this.toggleLoader(false);
            // this.loading.hide();
          }
        })
      );
    }

    const token = localStorage.getItem('token') as string;

    let newHeaders = req.headers;
    if (token != null) {
      newHeaders = newHeaders.append('Authorization', 'Bearer ' + token);
    }

    const authReq = req.clone({headers: newHeaders});

    return next.handle(authReq).pipe(
      finalize(() => {
        this.requests = this.requests - 1;
        if (this.requests === 0) {
          this.toggleLoader(false);
        }
      })
    );
  }

  toggleLoader(b: boolean) {
    if (!b) {
      $('#loaderBox').fadeOut();
    } else {
      $('#loaderBox').fadeIn();
    }
  }


}
