import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {Coupon} from '../../../model/coupon/coupon';

declare var $: any;

@Component({
  selector: 'app-modal-my-grades',
  templateUrl: './modal-my-grades.component.html',
  styleUrls: ['./modal-my-grades.component.scss']
})
export class ModalMyGradesComponent extends BaseComponent implements OnInit {

  @Input() arrList: Coupon[];

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: false,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,

        }
      }
    ]
  };
  isSinglePage = false;

  constructor(public router: Router) {
    super(router);
  }

  ngOnInit(): void {
  }

}
