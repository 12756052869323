import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class PartnerService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  getPartnerPage(s: string) {
    const url = `${this.BASE_URL}/partner/page${s}`;
    return this.http.get(url);
  }
  getRankingPage(s: string) {
    const url = `${this.BASE_URL}/partner/partnerRanking${s}`;
    return this.http.get(url);
  }

  getPartnerDashboard(s: string) {
    const url = `${this.BASE_URL}/partner/dashboard${s}`;
    return this.http.get(url);
  }

}
