<section class="page-single-terms page-single " [ngClass]="{'is-open': isPageSingle}">

  <div class="page-single-vw">
    <div class="page-single-vw-line"></div>
    <div class="page-single-vw-brand">
      <svg-icon class="svg svg-logo svg-primary" [src]="'assets/svg/logoVW.svg'"></svg-icon>
    </div>
    <div class="page-single-vw-line full"></div>
  </div>

  <div class="page-single-header terms-bg">
    <div class="page-single-header-content">
      <a href="javascript:void('');" (click)="goBack()" class="page-single-header-btn-terms">
        <svg-icon class="svg svg-md" [src]="'assets/svg/arrow.svg'"></svg-icon>
      </a>
      <h1 class="page-single-header-title">Regulamento</h1>
    </div>
  </div>

  <div class="container pb-5 mt-5">
    <!--
    <nav>
      <div class="nav nav-tabs border-0 mb-3 d-flex justify-content-center d-none" id="nav-tab" role="tablist">
        <a class="regulations_btn" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
           aria-controls="nav-home" aria-selected="true"><b>SORTEIO</b></a>
        <a class="regulations_btn" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
           aria-controls="nav-profile" aria-selected="false"><b>BRINDE</b></a>
        <a class="regulations_btn" id="nav-conc-tab" data-toggle="tab" href="#nav-conc" role="tab"
           aria-controls="nav-conc" aria-selected="false"><b>CONCESSIONÁRIAS PARTICIPANTES</b></a>
      </div>
    </nav>
    -->
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="container d-flex flex-column align-items-center">
          <div class="w-100 d-block text-right my-5">
            <a target="_blank" href="assets/files/Regulamento-AdrenalinaVW.pdf"
               class="btn btn-outline-primary"><b>Download do Regulamento PDF</b></a>
            <br>
            <br>
            <a target="_blank" href="assets/files/Pontuacao-Parceiros-AdrenalinaVW.pdf"
               class="btn btn-primary btnsmall"><b>Download do Regulamento de Parceiros</b></a>
          </div>
          <!--<b class="text-primary text-center row">Incentivo "Adrenalina"</b>
          <br>
          <div class="row">
            <div class="col-12"><p class="text-center text-primary"><b>VOLKSWAGEN DO BRASIL INDÚSTRIA DE
              VEICULOS
              AUTOMOTORES LTDA. </b> <br>
              MARGINAL DA VIA ANCHIETA, SN – KM 23,5 - DEMARCHI<br>
              CEP 09823-901 - São Bernardo do Campo/SP <br>
              CNPJ nº 59.104.422/0057-04</p></div>
          </div>-->

        </div>
        <div class="regulation mt-3">
          <h2>
            DESAFIO, DIVERSÃO E MUITA ADRENALINA!
          </h2>
          <h3>
            A hora é agora! A Campanha Adrenalina 2024 está começando, e desta
            vez, o desafio será mais emocionante do que nunca. Prepare-se para
            uma aventura que vai levar você até uma das paisagens mais belas do
            Brasil – ou para o outro lado do mundo!
          </h3>

          <p>
            A primeira etapa da campanha vai testar sua coragem e determinação. Enfrente os
            desafios dando o seu melhor. O que está em jogo é uma viagem para o maior parque
            temático da América Latina, o Beto Carrero World, em Santa Catarina. Mas se você quer
            mais adrenalina, prepare-se para a segunda etapa, que pode levar você para o Japão!
          </p>

          <p>
            Em qualquer parte do Brasil, ou do mundo, sua força e determinação em conquistar suas
            metas e objetivos fala mais alto.
          </p>

          <h3>
            A decisão está nas suas mãos. Está pronto(a) para o desafio?
          </h3>

          <p>
            Vamos juntos colocar os pés no chão com a máxima determinação
            para alçarmos voos sem limites!
          </p>

          <h3>QUANDO? </h3>

          <p>Novembro de 2023 a Agosto de 2024. </p>

          <h3>QUEM PARTICIPA?</h3>

          <p>
            Todas as Concessionárias Volkswagen Automóveis com concessão plena, e PATs que
            tenham sido nomeados até 30 de outubro de 2023.
          </p>
          <h3>
            QUAIS SÃO AS CATEGORIAS?
          </h3>

          <p>
            As concessionárias concorrerão em 4 categorias, no âmbito nacional, de acordo com o seu
            objetivo de compras validado no PAC Pós-Vendas 2023/2024 (Potencial Anual do Concessionário).
          </p>

          <h3>QUAIS SÃO OS CRITÉRIOS DE AVALIAÇÃO?</h3>
          <p>Pontuação Mensal – Itens regulares </p>
          <img src="../../../assets/images/terms/table1.JPG" alt="">

          <h3>AÇÕES EXTRAS</h3>
          <p>Adicionalmente aos pontos mensais regulares, ações pontuais poderão ocorrer durante a
            campanha, com prévio aviso.</p>

          <h3>CRITÉRIOS DE PONTUAÇÃO</h3>
          <p><strong>Os itens serão avaliados e pontuados mensalmente.</strong> <br>
            Para o cálculo da pontuação da etapa mensal, bem como da etapa final, serão somados
            os pontos conquistados nos meses referentes a cada uma das etapas.</p>

          <h3>CÁLCULO DA PONTUAÇÃO MENSAL</h3>
          <h3>1. PERFORMANCE DE COMPRAS DE PEÇAS</h3>
          <p>A pontuação será atribuída de acordo com a tabela abaixo, em função do percentual resultante
            do volume de pedidos colocados de Peças somados, pelos objetivos também somados de
            compras do mês.</p>
          <img src="../../../assets/images/terms/table2.JPG" alt="">

          <h3>2. PERFORMANCE DE COMPRAS DE ACESSÓRIOS</h3>
          <p>A pontuação será atribuída de acordo com a tabela abaixo, em função do percentual resultante
            do volume de pedidos colocados de Acessórios somados, pelos objetivos também somados de
            compras do mês.</p>

          <img src="../../../assets/images/terms/table3.JPG" alt="">

          <h3>3. EVOLUÇÃO DE PERFORMANCE (P.P) EM PEÇAS</h3>

          <p>A pontuação será atribuída conforme tabela abaixo em função da evolução, em (P.P), da
            performance de Peças quando comparada com o mesmo período do ano anterior.
          </p>

          <img src="../../../assets/images/terms/table4.JPG" alt="">

          <h3>4. EVOLUÇÃO DE PERFORMANCE (P.P) EM ACESSÓRIOS</h3>
          <p>A pontuação será atribuída conforme tabela seguinte em função da evolução, em (P.P), da
            performance de Acessórios quando comparada com o mesmo período do ano anterior.</p>

          <img src="../../../assets/images/terms/table5.JPG" alt="">

          <h3>
            5. AUTOPART
          </h3>

          <p>
            A pontuação será atribuída de acordo com a tabela abaixo em função do índice de pedidos
            colocados automaticamente pelo sistema, levando em consideração o percentual colocado.
          </p>

          <img src="../../../assets/images/terms/table6.JPG" alt="">
          <p><strong>Observação</strong> <br> Base extraída do ASAS, com exclusão de pedidos de carro parado, DSH, itens
            promocionais
            e ferramentas.</p>

          <h3>6. CEM: OBJETIVO CONCESSIONÁRIA (Q.6)</h3>

          <p>A pontuação será atribuída em função da avaliação do índice geral de satisfação da
            concessionária: CEM – Q6 (média móvel de 3 meses) .</p>
          <br>
          <p><strong>Será considerada a Q6 – Satisfação Geral, conforme as regras de bonificações
            atreladas ao CEM, SEM RED FLAG.</strong></p>

          <img src="../../../assets/images/terms/table7.JPG" alt="">

          <h3>7. AGENDAMENTO ONLINE</h3>

          <p>A pontuação será atribuída em função da avaliação da representatividade (penetration) de
            passagens agendadas pelos canais online em relação às passagens no concessionário.
            A avaliação será feita pelo cruzamento do chassi único apurado no Autoservice com a
            quantidade de agendamento online recebido mensalmente (não é cumulativo).</p>
          <br>
          <p>O concessionário, para ter direito à pontuação do Agendamento Online, deverá ter frequência
            de envio das notas fiscais de oficina igual ou superior a 80% mensalmente.
          </p>

          <img src="../../../assets/images/terms/table8.JPG" alt="">

          <h3>AGENDAMENTO DOS 3 CANAIS
            (EXCLUINDO TESTES)</h3>

          <img src="../../../assets/images/terms/table9.JPG" alt="">


          <h3>8. REVISÃO PLANEJADA</h3>

          <p>A pontuação será mensal e atribuída em função do penetration de vendas de pacotes de
            revisão atingido pela concessionária dentro do mês.</p>
          <br>
          <p>O cálculo do penetration é a divisão da quantidade de pacotes de Revisões Planejadas
            vendidas no concessionário em Vendas e no Pós Vendas, ou seja, independentemente
            do canal, dividido pela quantidade de veículos vendidos pelo concessionário, sem revisão
            de série, (Privado Varejo + Produtor Rural) dentro do mesmo mês.</p>

          <img src="../../../assets/images/terms/table10.JPG" alt="">

          <h3>9. CADÊNCIA DE PEDIDOS</h3>

          <p>O grupo deverá realizar em 2023:</p>

          <img src="../../../assets/images/terms/table11.JPG" alt="">

          <p>Regra para o ano de 2024:</p>

          <img src="../../../assets/images/terms/table12.JPG" alt="">

          <p><strong>Considerações Importantes:</strong></p>
          <br>
          <p>- Para o critério de dias uteis serão considerados apenas os feriados NACIONAIS.</p>
          <br>
          <p>- Caso o grupo não cumpra 100% de performance de peças no mês a pontuação será invalidada</p>
          <br>
          <p>- Para ter direito aos 100 pontos mensais é obrigatório que o grupo cumpra com as 2 faixas de cadencia
            estipuladas para o mês.</p>
          <br>
          <p><strong>
            OBSERVAÇÃO: o fechamento da Etapa Intermediária ocorrerá com base
            na pontuação acumulada até o dia 30/04/2024.
          </strong></p>

          <h3>10. ADESÃO AO E-COMMERCE PEÇAS.VW</h3>
          <p>- Cada concessionária que mantiver a sua adesão ao PEÇAS.VW
            <strong>receberá mensalmente 50 pontos.</strong></p>
          <br>
          <p>
            - Cada concessionária que mantiver a reputação em 5 (cinco) estrelas
            <strong>
              receberá mensalmente 50 pontos.
            </strong>
          </p>
          <br>
          <p>As pontuações serão atribuídas em função da adesão e reputação mensal dos
            concessionários publicados no PEÇAS.VW.</p>
          <p>As pontuações serão individuais por concessionária. Estarão elegíveis aos pontos mensais
            as concessionárias 100% publicadas até o último dia útil do mês avaliado.</p>
          <p>A avalição do status da publicação e da reputação de cada concessionário serão obtidas
            por meio da ferramenta de gestão BackOffice E-peças, disponível também aos
            concessionários publicados.</p>
          <h3>PONTUAÇÃO PARCEIROS</h3>

          <img src="../../../assets/images/terms/table13.JPG" alt="">


          <br>
          <img src="../../../assets/images/terms/table14.jpg" alt="">
          <h3>11.LINHA ECONOMY</h3>

          <p>A pontuação mensal terá como base o percentual comparativo entre o valor dos pedidos
            colocados no mês dos produtos da Linha Economy disponíveis e o objetivo mensal de
            compras da concessionária desses mesmos produtos. </p>

          <p>Os objetivos da Linha Economy são individuais e calculados utilizando o objetivo de compras
            de peças do PAC Pós-Vendas 2023 de cada concessionária, e estarão à sua disposição no site
            da campanha. </p>

          <p><strong>A pontuação de cumprimento do objetivo será de acordo com a escala abaixo:</strong></p>

          <img src="../../../assets/images/terms/table15.JPG" alt="">

          <p><strong>NOTA: serão consideradas na campanha todas as peças disponíveis da Linha Economy</strong></p>

          <img src="../../../assets/images/terms/table16.jpg" alt="">
          <br>
          <br>
          <img src="../../../assets/images/terms/table17.jpg" alt="">

          <h3>12. ÓLEO ORIGINAL VOLKSWAGEN MAXI PERFORMANC</h3>

          <p>A pontuação mensal terá como base o percentual comparativo entre o volume em litros
            dos pedidos colocados no mês e o volume mensal objetivado.</p>

          <p>• Para se ter elegibilidade da pontuação, o grupo econômico deve bater o alvo de óleo de motor.</p>

          <p><strong>A pontuação de cumprimento do objetivo será de acordo com a escala abaixo:</strong></p>

          <img src="../../../assets/images/terms/table18.JPG" alt="">

          <p><strong>
            NOTA: São considerados na campanha todos os óleos comercializados pela Volkswagen
            (litro ou tambor Maxi Performance).
          </strong></p>

          <p><strong>PRODUTOS MAXI PERFORMANCE AVALIADOS NA CAMPANHA</strong></p>


          <img src="../../../assets/images/terms/table19.JPG" alt="">

          <h3>PONTUAÇÕES ESPECIAIS NO FINAL DA CAMPANHA</h3>

          <img src="../../../assets/images/terms/table20.JPG" alt="">

          <h3>1. PROGRAMA AUTONOMIA</h3>

          <p>Durante o período da campanha, haverá 2 medições consolidadas do Programa Autonomia:</p>


          <p><strong>• meses de janeiro, fevereiro e março (resultado válido para o 1º Tri/24);</strong></p>
          <br>
          <p><strong>• meses de abril, maio e junho (resultado válido para o 2º Tri/24).</strong></p>
          <br>
          <p>
            Essas medições indicarão a evolução do concessionário durante as duas classificações do
            Programa Autonomia realizadas dentro do período da campanha. Cada concessionário
            receberá uma pontuação específica, de acordo com tais classificações.
          </p>

          <p>
            <strong>500 PONTOS</strong>
            S - DNs classificados como
            <strong> o A+</strong>
            na medição trimestral do Programa Autonomia
          </p>
          <br>
          <p><strong>250 PONTOS </strong>
            DNs classificados como
            <strong>A</strong>
            na medição trimestral do Programa Autonomia
          </p>
          <br>

          <p><strong>125 PONTOS</strong>DNs classificados como
            <strong>B</strong>
            na medição trimestral do Programa Autonomia
          </p>
          <br>

          <p><strong>000 PONTOS</strong> DNs classificados como <strong>C</strong> na medição trimestral do Programa
            Autonomia</p>

          <p><strong>Exemplificando, o concessionário XPTO recebeu as seguintes classificações:
          </strong></p>
          <br>
          <p><strong>• Período de janeiro, fevereiro e março (resultado válido para o 1º Tri/24): A+</strong></p>
          <br>
          <p><strong>• Período de abril, maio e junho (resultado válido para o 2º Tri/24): B;</strong></p>

          <p>Nesse contexto, o concessionário XPTO receberá 500 PONTOS referente à classificação A+ no
            primeiro período e mais 125 pontos referente à classificação B do segundo período,
            totalizando 625 pontos para a campanha Adrenalina. A pontuação referente ao Programa
            Autonomia será atribuída apenas no final da campanha.</p>

          <h3>2.ÍNDICE DE CAPACITAÇÃO DE PÓS-VENDAS (ANUAL)</h3>

          <img src="../../../assets/images/terms/table21.JPG" alt="">
          <br>
          <img src="assets/iamges/table22.JPG" alt="">

          <h3>3. CSI OU PESQUISA CLUBE (ACUMULADO DAS ONDAS 1-6/24)
            (ANUAL)</h3>

          <p><strong>CSI (Customer Satisfaction Index)</strong> <br>
            Pontua a concessionária que obtiver a nota acumulada das ondas 1 a 6,
            maior ou igual a 86,0% na nota top 2 box (9 e 10).</p>

          <p>PONTUAÇÃO: <strong>500 PONTOS</strong></p>

          <p>Caso alguma concessionária desse grupo não possua entrevista, esse critério perde a validade.</p>

          <p><strong>OBS. 1</strong>: válida apenas para concessionários da categoria A.</p>

          <h3>REGRA DE ELEGIBILIDADE</h3>

          <p>Para ser elegível à premiação da campanha (Etapa Intermediária ou Etapa Final), além de
            estar classificada pelo total de pontos na sua categoria, a concessionária terá de cumprir
            também as seguintes condições:</p>

          <p><strong>1-</strong>Alcançar, no mínimo, 100% do seu objetivo acumulado de compras de Peças e
            Acessórios nos meses do período avaliado;</p>

          <p><strong>2-</strong>Se a concessionária ganhadora pertencer a determinado Grupo Econômico
            Regionalizado (conforme regras do protocolo da política de comercialização de Peças e
            Acessórios), para fazer jus às premiações, o grupo todo deverá alcançar, no mínimo, 100%
            do objetivo acumulado de compras de Peças e Acessórios nos meses do período avaliado;</p>

          <p><strong>3-</strong>A concessionária deve possuir a certificação do ATC no mês do fechamento das etapas;</p>

          <p><strong>4-</strong>Ao final do período da campanha, a concessionária deve ter pontuado no mínimo 2.000
            pontos no acumulado do período com os fornecedores parceiros, independentemente de
            quais parceiros a concessionária escolheu adquirir seus produtos; </p>

          <p><strong>5-</strong> O DN precisar estar devidamente com PAC Pós-Vendas 2023/2024 validado e assinado</p>

          <p><strong>NOTA: caso alguma das concessionárias classificadas em uma categoria (pelo total de
            pontos) deixe de cumprir alguma das condições de elegibilidade, o prêmio passará
            automaticamente à concessionária colocada imediatamente abaixo na escala de
            pontuação. </strong></p>

          <p><strong>Caso nenhuma das concessionárias, de determinada categoria, satisfaça a todas as
            condições de elegibilidade, as vagas disponíveis para essa categoria ficarão a cargo do
            comitê da campanha, que será composto por todos os gestores diretos do Diretor de
            Pós-Vendas.</strong></p>

          <h3>CRITÉRIOS DE DESEMPATE</h3>

          <p><strong>1-</strong>Entre duas ou mais concessionárias empatadas na pontuação da Etapa Intermediária ou
            Final, será vencedora aquela que possuir maior performance de peças no grupo.</p>

          <p><strong>2-</strong>Persistindo o empate pelo primeiro critério, será vencedora a concessionária que possuir
            maior performance de Acessórios no grupo.</p>

          <p><strong>3-</strong>Se depois de aplicado esses dois critérios ainda persistir o empate, será vencedora a
            concessionária que obtiver o melhor índice do CEM.</p>

          <h3>CRITÉRIOS DE PREMIAÇÃO</h3>



          <p><strong>1 -</strong> PREMIAÇÃO INTERMEDIÁRIA (GERENTES E EQUIPES)</p>

          <img src="../../../assets/images/terms/table23.JPG" alt="">



          <p>A Etapa Intermediária terá avaliação nacional dentro das quatro categorias (A, B, C e D),
            pontuando mensalmente as seguintes concessionárias:</p>

          <p><strong>Categoria A – </strong> sete primeiras concessionárias;</p>
          <p><strong>Categoria B – </strong> seis primeiras concessionárias;</p>
          <p><strong>Categoria C – </strong> cinco primeiras concessionárias; </p>
          <p><strong>Categoria D – </strong> quatro primeiras concessionárias.</p>

          <img src="../../../assets/images/terms/table24.JPG" alt="">

          <p><strong>OBS:</strong>  o fechamento da Etapa Intermediária ocorrerá com base na pontuação acumulada
            até o dia 31/05/2023</p>

          <br>
          <p><strong>2 - PREMIAÇÃO ETAPA FINAL (GERENTES E ACOMPANHANTES)</strong></p>

          <img src="../../../assets/images/terms/table25.JPG" alt="">

          <p>A etapa final terá avaliação nacional dentro das quatro categorias (A, B, C e D),
            premiando da seguinte forma: </p>

          <p><strong>Categoria A – </strong> três primeiras concessionárias;</p>
          <p><strong>Categoria B – </strong> duas primeiras concessionárias;</p>
          <p><strong>Categoria C – </strong> duas primeiras concessionárias; </p>
          <p><strong>Categoria D – </strong> duas primeiras concessionárias.</p>

          <p>Caso alguma concessionária de uma ou mais categorias não se classifique, as vagas
            disponíveis ficarão a cargo do comitê da campanha. Premiação destinada aos gerentes e
            acompanhantes de Pós-Vendas das concessionárias mais bem classificadas em cada
            categoria no período de janeiro a agosto de 2023, na quantidade prevista para cada
            categoria, conforme tabela a seguir.</p>

          <img src="../../../assets/images/terms/table26.JPG" alt="">

          <p><strong>NOTAS <br>
            • Caso a concessionária premiada possua apenas um gerente de Pós-Vendas
            responsável pelas duas áreas (Peças e Serviços), poderá indicar alguém da área de
            Pós-Vendas da concessionária para a vaga aberta. <br>
            • Cada gerente poderá optar por levar apenas funcionário de Pós-Vendas para a viagem
            (Etapa Intermediária). <br>
            • Cada gerente poderá optar por levar um funcionário de Pós-Vendas ou acompanhante
            para a viagem (etapa final). <br>
            • A Volkswagen terá o prazo de até um ano para a entrega da viagem ou um prazo
            maior, caso não seja possível a realização da viagem por motivo de força maior. <br>
            • A Volkswagen se mantém no direito de alterar o destino da viagem de premiação da
            campanha por qualquer motivo de força maior.</strong></p>


          <h3>ESCLARECIMENTOS FINAIS</h3>

          <p>Quanto aos resultados, é preciso que se esclareça que a Volkswagen poderá reavaliar, a
            qualquer momento, as premiações atribuídas, mediante análise posterior, declarando os
            legítimos vencedores, caso seja constatada alguma irregularidade em dados ou nos
            resultados fornecidos pelas próprias concessionárias.</p>

          <p>No fechamento de cada etapa da campanha, todos os pedidos cancelados, seja pela
            concessionária ou automaticamente pelo sistema, passarão por auditoria para validação de
            sua autenticidade. </p>

          <p>A Volkswagen se reserva também o direito de desclassificar da campanha, a qualquer
            momento, concessionárias que venham a adotar procedimentos incompatíveis com os
            padrões de conduta, ainda que tais procedimentos não estejam previstos de forma explícita
            no regulamento. Todas as concessionárias estarão sujeitas a auditoria por parte da
            Volkswagen quanto aos dados fornecidos para a campanha.</p>



        </div>
        <div class="d-none regulation mt-3">
          <h2>DESAFIO, DIVERSÃO E MUITA ADRENALINA!</h2>
          <p>Já sonhou em viver uma experiência inesquecível na Amazônia ou caminhar pelas paisagens deslumbrantes de
            Ushuaia, na Patagônia?</p>
          <p>Com a nova Campanha Adrenalina 2023, você pode tornar esse sonho realidade.</p>
          <p> Permita-se alcançar suas metas e objetivos, independentemente do lugar. Não importa se você está
            enfrentando o calor abrasador da floresta tropical ou o frio congelante da região da Antártida, nós
            estaremos aqui para te dar todas as ferramentas para que você possa alcançar o extremo das suas
            conquistas.</p>
          <h3>QUANDO?</h3>
          <p> De Janeiro a Agosto de 2023. </p>
          <h3>QUEM PARTICIPA?</h3>
          <p> Participam dela todas as Concessionárias Volkswagen Automóveis <br>
            com concessão plena, e PATs que tenham sido nomeados até 30 de dezembro de 2022.</p>
          <h3>QUAIS SÃO AS CATEGORIAS?</h3>
          <p> As concessionárias concorrerão em 4 categorias, no âmbito nacional, de acordo com o seu objetivo de
            compras validado no PAC Pós-Vendas 2022 (Potencial Anual do Concessionário).</p>
          <h3>QUAIS SÃO OS CRITÉRIOS DE AVALIAÇÃO?</h3>
          <p> Pontuação Mensal – Itens regulares</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-1.png" alt=""/></p>
          <p>&nbsp;</p>
          <h3>AÇÕES EXTRAS</h3>
          <p> Adicionalmente aos pontos mensais regulares, ações pontuais poderão ocorrer durante a campanha, com prévio
            aviso.</p>
          <h3>CRITÉRIOS DE PONTUAÇÃO</h3>
          <p> Os itens serão avaliados e pontuados mensalmente. </p>
          <p> Para o cálculo da pontuação da etapa mensal, bem como da etapa final, serão somados
            os pontos conquistados nos meses referentes a cada uma das etapas.</p>
          <h3>CÁLCULO DA PONTUAÇÃO MENSAL</h3>
          <p><strong>1. Performance de compraS de Peças</strong></p>
          <p>A pontuação será atribuída de acordo com a tabela abaixo, em função do percentual resultante do volume de
            pedidos colocados de Peças somados, pelos objetivos também somados de compras do mês.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-2.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>2. Performance de compraS de ACESSÓRIOS</strong></p>
          <p>A pontuação será atribuída de acordo com a tabela abaixo, em função do percentual resultante do volume de
            pedidos colocados de Acessórios somados, pelos objetivos também somados de compras do mês.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-3.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>3. Evolução de Performance (P.P) em Peças</strong></p>
          <p>A pontuação será atribuída conforme tabela abaixo em função da evolução, em (P.P), da performance de Peças
            quando comparada com o mesmo período do ano anterior.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-4.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>4. Evolução de Performance (P.P) em Acessórios</strong></p>
          <p>A pontuação será atribuída conforme tabela seguinte em função da evolução, em (P.P), da performance de
            Acessórios quando comparada com o mesmo período do ano anterior.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-5.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>5. Autopart</strong></p>
          <p>A pontuação será atribuída de acordo com a tabela abaixo em função do índice de pedidos colocados
            automaticamente pelo sistema, levando em consideração o percentual colocado.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-6.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>Observação</strong></p>
          <p> Base extraída do ASAS, com exclusão de pedidos de carro parado, DSH, itens promocionais
            e ferramentas.</p>
          <p><strong>6. CEM: Objetivo Concessionária (Q.6)</strong></p>
          <p>A pontuação será atribuída em função da avaliação do índice geral de satisfação da concessionária: CEM – Q6
            (média móvel de 3 meses).</p>
          <p>Será considerada a Q6 – Satisfação Geral, conforme as regras de bonificações
            atreladas ao CEM, SEM RED FLAG.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-7.png" alt=""/></p>
          <p>&nbsp;</p>

          <p><strong>7. Agendamento Online</strong></p>
          <p>A pontuação será atribuída em função da avaliação de passagens agendadas por meio do Agendamento Online no
            site e a avaliação será feita pelo cruzamento do chassi único apurado no Autoservice com a quantidade de
            leads recebidos mensalmente (não é cumulativo). </p>
          <p>O concessionário, para ter direito à pontuação do Agendamento Online, deverá ter frequência de envio de
            registros do Autoservice igual ou superior a 80% mensalmente.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-8.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/fig-1.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>8. Revisão Planejada</strong></p>
          <p>A pontuação será mensal e atribuída em função do penetration de vendas de pacotes de revisão atingido pela
            concessionária dentro do mês. O cálculo do penetration é a divisão da quantidade de pacotes de Revisões
            Planejadas vendidas no concessionário em Vendas e no Pós Vendas, ou seja, independentemente do canal,
            dividido pela quantidade de veículos vendidos pelo concessionário, sem revisão de série, (Privado Varejo +
            Produtor Rural) dentro do mesmo mês.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-9.png" alt=""/></p>
          <p>&nbsp;</p>
          <!--<p>Os pacotes vendidos no Pós-Vendas serão identificados da  seguinte forma:</p>
          <p>- Pacotes vendidos para veículos com a km maior ou igual a  500 km na data da sua aquisição; <br>
            - Pacotes vendidos na data da realização da 1ª Revisão ou em  data próxima por meio da equipe de agendamento; <br>
            - Pacotes vendidos pelo tablet do Consultor Técnico ATC 2.0,  DMS ou canais online, independente de qual a revisão a ser agendada pelo  cliente, desde que sejam os pacotes da Volkswagen em parceria com o Banco  Volkswagen.</p>
          <p>As passagens elegíveis serão calculadas da seguinte forma:</p>
          <p>- Apenas passagens dos modelos elegíveis para a venda do  pacote de Revisões Planejadas; <br>
            - Volume de passagens conforme informações enviadas pela  concessionária por meio do Autoservice, considerando apenas as fontes pagadoras  1, 8, 10, 14, 15 e 16; <br>
            - Concessionárias sem envio do Autoservice não serão  consideradas.</p>-->
          <p><strong>9. Cadência de pedidos</strong></p>
          <p>O grupo deve realizar 50% do objetivo de peças até o dia 15 (metade do mês) e 80% até 3 dias úteis antes do
            fechamento do mês (feriados regionais serão desconsiderados). Caso o grupo não cumpra 100% de performance de
            peças no mês a pontuação será invalidada.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-10.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>OBSERVAÇÃO:</strong> critério válido de fevereiro a agosto.</p>
          <p><strong> 10. PONTUAÇÃO PARCEIROS</strong></p>
          <p>OBSERVAÇÕES GERAIS (Válidas para todos os parceiros) </p>
          <p>1. Os produtos são aplicados na campanha pelo preço com IPI, PIS, COFINS, ICMS <br>
            (regionalizado). </p>
          <p>2. Quando o pedido é colocado pela concessionária, o sistema já calcula o ICMS do estado. </p>
          <p>&nbsp;</p>
          <p><img style="width: 25%" src="assets/images/terms/fig-2.png" alt=""/></p>
          <p>&nbsp;</p>
          <h3>LINHA ECONOMY</h3>
          <p>A pontuação mensal terá como base o percentual comparativo entre o valor dos pedidos colocados no mês dos
            produtos da Linha Economy disponíveis e o objetivo mensal de compras da concessionária desses mesmos
            produtos.</p>
          <p>Os objetivos da Linha Economy são individuais e calculados utilizando o objetivo de compras de peças do PAC
            Pós-Vendas 2023 de cada concessionária e estarão à sua disposição no site da campanha.</p>
          <p>A pontuação de cumprimento do objetivo será de acordo com a escala abaixo:</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-11.png" alt=""/></p>
          <p>&nbsp;</p>
          <p>NOTA: serão consideradas na campanha todas as peças disponíveis da Linha Economy.</p>
          <p><strong>FIQUE ATENTO!</strong></p>
          <p>Demais parceiros serão anunciados em Fevereiro/23 em um documento complementar à este regulamento, assim
            como produtos e pontuações correspondentes!</p>
          <h3><strong>PONTUAÇÕES ESPECIAIS NO FINAL DA CAMPANHA</strong></h3>
          <p><strong>OBSERVAÇÕES GERAIS</strong></p>
          <p>1  Ao final dos oito meses de campanha, cada concessionária que cumprir um dos critérios abaixo ganhará uma
            pontuação extra correspondente. <br>
            2  Cada um dos critérios apresentam pontuações diferentes. <br>
            3  Pontuações válidas apenas para a etapa final.</p>
          <p><strong>1. VENDAS ONLINE DE PEÇAS E ACESSÓRIOS </strong></p>
          <p>Toda concessionária engajada nas iniciativas de Vendas Online oficiais da VW (Peças.VW, Mercado Livre e
            B2W) terão pontuação extra no final da campanha.</p>
          <p>A pontuação funcionará da seguinte maneira:</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/fig-3.png" alt=""/></p>
          <p>&nbsp;</p>
          <p>Serão desclassificados concessionários que desrespeitarem as regras da Loja Oficial Volkswagen. Exemplo:
            vendas de produtos não originais, não homologados, usados e de outras marcas.</p>
          <p>Adesão ao e-commerce PEÇAS.VW:</p>
          <p>Cada concessionária publicada no PEÇAS.VW, no final da campanha receberá pontuação extra.</p>
          <p>A pontuação será individual por concessionária e funcionará da seguinte maneira:</p>
          <p>50 pontos</p>
          <p>-  Para concessionárias 100% publicadas até o final da campanha.</p>
          <p>Durante o período da campanha a concessionária que rejeitar pedidos, por erros de precificação ou quebra de
            estoque por inventário, serão desclassificadas.</p>
          <p><strong>2. AUTOSERVICE ENVIO DE REGISTROS</strong></p>
          <p>Toda concessionária com a Média de Frequência de Recebimento dos registros no Autoservice maior ou igual a
            80% dentro dos meses da campanha será pontuada com 400 pontos.</p>
          <p><strong>3. PROGRAMA AUTONOMIA</strong></p>
          <p>Durante o período da campanha, haverá 2 medições consolidadas do Programa Autonomia: <br>
            • meses de janeiro, fevereiro e março (resultado válido para o 1º Tri/23);<br>
            • meses de abril, maio e junho (resultado válido para o 2º Tri/23). </p>
          <p>Essas medições indicarão a evolução do concessionário durante as duas classificações do Programa Autonomia
            realizadas dentro do período da campanha. Cada concessionário receberá uma pontuação específica, de acordo
            com tais classificações.</p>
          <p>500 pontos - DNs classificados como A+ na medição trimestral do Programa Autonomia <br>
            250 pontos - DNs classificados como A na medição trimestral do Programa Autonomia<br>
            125 pontos - DNs classificados como B na medição trimestral do Programa Autonomia <br>
            000 pontos - DNs classificados como C na medição trimestral do Programa Autonomia </p>
          <p>Exemplificando, o concessionário XPTO recebeu as seguintes classificações: </p>
          <p>- Período de janeiro, fevereiro e março (resultado válido para o 1º Tri/22): A+ <br>
            - Período de abril, maio e junho (resultado válido para o 2º Tri/22): B </p>
          <p>Nesse contexto, o concessionário XPTO receberá 500 pontos referente à classificação A+ no primeiro período
            e mais 125 pontos referente à classificação B do segundo período, totalizando 625 pontos para a campanha
            Adrenalina. A pontuação referente ao Programa Autonomia será atribuída apenas no final da campanha.</p>
          <p><strong>4. ÍNDICE DE CAPACITAÇÃO DE PÓS-VENDAS (ANUAL)</strong></p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/fig-3.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>5. CSI ou Pesquisa Clube (ACUMULADO DAS ONDAS 1-4/23) (ANUAL)</strong></p>
          <p>CSI (Customer Satisfaction Index) -  Pontua a concessionária que obtiver a nota acumulada das ondas 1 a 4,
            maior ou igual a 85,0% na nota top 2 box (9 e 10); </p>
          <p>Caso alguma concessionária desse grupo não possua entrevista, esse critério perde a validade.</p>
          <p>OBS: Válida apenas para concessionários da categoria A.</p>
          <p>&nbsp;</p>
          <h3>REGRA DE ELEGIBILIDADE</h3>
          <p>Para ser elegível à premiação da campanha (Etapa Intermediária ou Etapa Final), além de estar classificada
            pelo total de pontos na sua categoria, a concessionária terá de cumprir também as seguintes condições:</p>
          <p>1- Alcançar, no mínimo, 100% do seu objetivo acumulado de compras de Peças e Acessórios nos meses do
            período avaliado.</p>
          <p>2- Se a concessionária ganhadora pertencer a determinado Grupo Econômico Regionalizado (conforme regras do
            protocolo da política de comercialização de Peças e Acessórios), para fazer jus às premiações, o grupo todo
            deverá alcançar, no mínimo, 100% do objetivo acumulado de compras de Peças e Acessórios nos meses do período
            avaliado.</p>
          <p>3- A concessionária deve possuir a certificação do ATC no mês do fechamento das etapas.</p>
          <p>4- Ao final do período da campanha, a concessionária deve ter pontuado no mínimo 2.000 pontos no acumulado
            do período com os fornecedores parceiros, independentemente de quais parceiros a concessionária escolheu
            adquirir seus produtos. </p>
          <p><strong>NOTA</strong></p>
          <p> Caso alguma das concessionárias classificadas em uma categoria (pelo total de pontos) deixe de cumprir
            alguma das condições de elegibilidade, o prêmio passará automaticamente à concessionária colocada
            imediatamente abaixo na escala de pontuação. <br>
            Caso nenhuma das concessionárias, de determinada categoria, satisfaça a todas as condições de elegibilidade,
            as vagas disponíveis para essa categoria ficarão a cargo do comitê da campanha, que será composto por todos
            os gestores diretos do Diretor de Pós-Vendas.</p>
          <p><strong>CRITÉRIOS DE PREMIAÇÃO</strong></p>
          <p>1 - Premiação Intermediária (Gerentes e Equipes)</p>
          <p>A premiação da Etapa Intermediária para as Equipes:<br>
            Uma viagem de 3 dias pela Amazônia.</p>
          <p>A Etapa Intermediária terá avaliação nacional, dentro das quatro categorias (A, B, C e D), pontuando
            mensalmente as seguintes concessionárias:</p>
          <p>Categoria A - nove primeiras concessionárias<br>
            Categoria B - oito primeiras concessionárias<br>
            Categoria C - sete primeiras concessionárias<br>
            Categoria D - seis primeiras concessionárias</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-12.png" alt=""/></p>
          <p>&nbsp;</p>
          <p>OBS: O fechamento da Etapa Intermediária ocorrerá com base na pontuação acumulada <br>
            até o dia 31/05/2023</p>
          <p>2 – Premiação Etapa Final (gerentes e acompanhantes) </p>
          <p> Viagem para Ushuaia.</p>
          <p>A etapa final terá avaliação nacional dentro das quatro categorias (A, B, C e D), premiando da seguinte
            forma:</p>
          <p>Categoria A - quatro primeiras concessionárias<br>
            Categoria B - três primeiras concessionárias<br>
            Categoria C - três primeiras concessionárias<br>
            Categoria D - duas  primeiras concessionárias</p>
          <p>Caso alguma concessionária de uma ou mais categorias não se classifique, as vagas disponíveis ficarão a
            cargo do comitê da campanha. </p>
          <p>Premiação destinada aos gerentes e acompanhantes de Pós-Vendas das concessionárias mais bem classificadas
            em cada categoria no período de Janeiro a Agosto de 2023, na quantidade prevista para cada categoria,
            conforme tabela a seguir.</p>
          <p>&nbsp;</p>
          <p><img src="assets/images/terms/table-13.png" alt=""/></p>
          <p>&nbsp;</p>
          <p><strong>NOTAS</strong></p>
          <p>- Caso a concessionária premiada possua apenas um gerente de Pós-Vendas responsável pelas duas áreas (Peças
            e Serviços), ele poderá indicar alguém da área de Pós-Vendas da concessionária para a vaga aberta;</p>
          <p>- Cada gerente poderá optar por levar um funcionário ou acompanhante para a viagem;</p>
          <p>- A Volkswagen terá o prazo de até um ano para a entrega da viagem ou um prazo maior, caso não seja
            possível a realização da viagem por motivo de força maior;</p>
          <p>- A Volkswagen se mantém no direito de alterar o destino da viagem de premiação
            da campanha por qualquer motivo de força maior.</p>
          <h3>ESCLARECIMENTOS FINAIS</h3>
          <p>Quanto aos resultados, é preciso que se esclareça que a Volkswagen poderá reavaliar, a qualquer momento, as
            premiações atribuídas, mediante análise posterior, declarando os legítimos vencedores, caso seja constatada
            alguma irregularidade em dados ou nos
            resultados fornecidos pelas próprias concessionárias.</p>
          <p>No fechamento de cada etapa da campanha, todos os pedidos cancelados, seja pela concessionária ou
            automaticamente pelo sistema, passarão por auditoria para validação
            de sua autenticidade.</p>
          <p>A Volkswagen se reserva também o direito de desclassificar da campanha, a qualquer momento, concessionárias
            que venham a adotar procedimentos incompatíveis com os padrões de conduta, ainda que tais procedimentos não
            estejam previstos de forma explícita <br>
            no regulamento. Todas as concessionárias estarão sujeitas a auditoria por parte da Volkswagen quanto aos
            dados fornecidos para a campanha.</p>
        </div>

      </div>

    </div>


  </div>

  <app-footer></app-footer>


</section>

