<div class="modal fade modal-custom" id="modalLuckyNumber" tabindex="-1" role="dialog"
     aria-labelledby="modalLoginTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 666px" role="document">
    <div class="modal-content">
      <div class="modal-blocks">
        <img src="assets/images/block-close.png" alt="Fechar" class="modal-blocks modal-blocks-close"
             data-dismiss="modal" aria-label="Close"/>
      </div>
      <div class="modal-content-box">
        <div class="modal-body w-100 m-0">
          <h2 class="modal-body-title mb-2">Meus números da sorte</h2>
          <p class="modal-body-subtitle">Confira abaixo os seus números da sorte que estão concorrendo.</p>
          <div class="d-block py-5 w-100 text-center" *ngIf="arrList.length === 0">Nenhum registro encontrado.</div>
          <div class="my-number-item" *ngFor="let i of arrList">
            <div class="my-number-pincode">
              <span class="my-number-pincode-label">PINCODE</span>
              <span class="my-number-pincode-value">{{i.pincode}}</span>
            </div>
            <div class="my-number-lucky">
              <span class="my-number-lucky-label">Série / Nº da Sorte</span>
              <div class="my-number-lucky-card">
                <span class="my-number-lucky-value">{{i.luckyNumber.serie}} / {{i.luckyNumber.number}}</span>
              </div>
            </div>
            <span class="my-number-date">{{i.luckyNumber.createdAt | date: 'dd/MM/yyyy'}}</span>
          </div>

        </div>

      </div>
      <div class="modal-content-footer">
        <img src="assets/images/modal-footer.png" alt="VW"/>
      </div>
    </div>
  </div>
</div>
