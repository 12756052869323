<section id="home" class="home ts-background">
  <!--      <img src="assets/images/hero.png" class="hero-brand" alt="Hero"/>-->
  <img class="home_tokyo thumbnail" src="assets/images/homeimg1.png" alt="image">
  <img class="home_beto thumbnail" src="assets/images/homeimg2.png" alt="image">

  <svg-icon [@scrollAnimation]="scrollState" (click)="scrollDown()" id="yourTargetElementId" class="arrowhome"
            src="assets/svg/arrowdrop.svg"></svg-icon>
</section>


<section id="como-participar" class="campaign">
  <div class="bg-gradient">&nbsp;</div>
  <div class="container">

    <h2 class="home_title"><span>A Campanha</span></h2>
    <div class="row">
      <div class="col-lg-6">
        <h3 class="campaign_subtitle">DESAFIO, DIVERSÃO E MUITA ADRENALINA!</h3>
        <p class="campaign_description">
          A hora é agora! A Campanha Adrenalina 2024 está começando, e desta vez, o desafio será mais emocionante do que
          nunca.
        </p>
        <p class="home_text">
          A primeira etapa da campanha vai testar sua coragem e determinação. Enfrente os desafios dando o seu melhor. O
          que está em jogo é uma viagem para o maior parque temático da América Latina, o Beto Carrero World, em Santa
          Catarina. Mas se você quer mais adrenalina, prepare-se para a segunda etapa, que pode levar você para o Japão!
        </p>
        <p class="home_text">
          Em qualquer parte do Brasil, ou do mundo, sua força e determinação em conquistar suas metas e objetivos fala
          mais alto.
        </p>
        <p class="home_text text-secondary">
          A decisão está nas suas mãos. Está pronto(a) para o desafio?
        </p>
        <p class="home_text">
          Vamos juntos colocar os pés no chão com a máxima determinação para alçarmos voos sem limites!
        </p>
      </div>
      <div class="col-lg-6">
        <div>
          <dl class="campaign_card">
            <dt class="campaign_card_lbl">QUANDO?</dt>
            <dd class="campaign_card_value value-lg">
              <span><strong>Novembro de 2023  a  Agosto de 2024</strong></span>
            </dd>
          </dl>
        </div>
        <div>
          <dl class="campaign_card">
            <dt class="campaign_card_lbl">QUEM PARTICIPA?</dt>
            <dd class="campaign_card_value">
              Todas as Concessionárias Volkswagen Automóveis com concessão plena, e PATs que tenham sido nomeados até 30
              de outubro de 2023.
            </dd>
          </dl>
        </div>
        <div>
          <dl class="campaign_card">
            <dt class="campaign_card_lbl">QUAIS SÃO AS CATEGORIAS?</dt>
            <dd class="campaign_card_value">
              As concessionárias concorrerão em 4 categorias, no âmbito nacional, de acordo com o seu objetivo de
              compras validado no PAC Pós-Vendas 2023/2024 (Potencial Anual do Concessionário).
            </dd>
          </dl>
        </div>
      </div>
    </div>

  </div>
</section>

<section id="premios" class="awards">
  <div class="container">
    <h2 class="home_title"><span>Prêmiação</span></h2>
  </div>

  <div class="awards_col">
    <div class="container">
      <p class="awards_legend">Premiação Intermediária<br>
        <span>(Gerentes e Equipes)</span></p>
      <div class="row awards_secondary">
        <div class="col-sm-5">
          <div class="d-flex align-items-center mb-4">
            <img class="awards_icon" src="assets/images/carrero-logo.png" alt="carrero">
            <h3 class="awards_subtitle">BALNEÁRIO<br>
              <span>CAMBORIÚ</span></h3>
          </div>
        </div>
        <div class="col-sm-7" style="margin-bottom: -192px">
          <div class="awards_card">
            <p class="home_text text-primary mb-4">
              Uma viagem de 3 dias para Balneário Camboriú, com um dia de <strong>Parque Beto Carrero World.</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="awards_slide">
      <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" id="slickSlider2">
        <div ngxSlickItem *ngFor="let x of this.cruise; let i = index">
          <img class="awards_img" [ngStyle]="{'width':x.width}" src="assets/images/premiation-1/{{x.image}}"
               alt="slide">
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-5">
          <p class="home_text pt-5 pb-3">
            A Etapa Intermediária terá avaliação nacional, dentro das quatro categorias (A, B, C e D), pontuando
            mensalmente as seguintes concessionárias:
          </p>
          <dl class="home_list">
            <dt class="home_list_lbl">Categoria A</dt>
            <dd class="home_list_value">7 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl">Categoria B</dt>
            <dd class="home_list_value">6 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl">Categoria C</dt>
            <dd class="home_list_value">5 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl">Categoria D</dt>
            <dd class="home_list_value">4 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list stage">
            <dd class="home_list_value">O fechamento da Etapa Intermediária ocorrerá com base na pontuação acumulada até
              o dia 31/05/2023
            </dd>
          </dl>
        </div>
        <div class="col-sm-7">
          <div class="awards_card rounded-0">
            <div class="table-responsive">
              <table class="table table-secondary w-100">
                <thead class="thead-dark">
                <tr class="w-100">
                  <th scope="col">Categorias</th>
                  <th class="text-center bottom-bar" scope="col">A</th>
                  <th class="text-center bottom-bar" scope="col">B</th>
                  <th class="text-center bottom-bar" scope="col">C</th>
                  <th class="text-center bottom-bar" scope="col">D</th>
                  <th class="text-center bottom-bar" scope="col"><strong>Total</strong></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Quantidade de concessionárias premiadas</td>
                  <td class="text-center">7</td>
                  <td class="text-center">6</td>
                  <td class="text-center">5</td>
                  <td class="text-center">4</td>
                  <td class="text-center">22</td>
                </tr>
                <tr>
                  <td>Quantidade de gerentes premiados por concessionária</td>
                  <td class="text-center">2</td>
                  <td class="text-center">2</td>
                  <td class="text-center">2</td>
                  <td class="text-center">2</td>
                  <td class="text-center">8</td>
                </tr>
                <tr>
                  <td>Quantidade de funcionários premiados por concessionária</td>
                  <td class="text-center">3</td>
                  <td class="text-center">3</td>
                  <td class="text-center">2</td>
                  <td class="text-center">2</td>
                  <td class="text-center">10</td>
                </tr>
                <tr>
                  <td>Total de premiados por concessionária</td>
                  <td class="text-center">5</td>
                  <td class="text-center">5</td>
                  <td class="text-center">4</td>
                  <td class="text-center">4</td>
                  <td class="text-center">18</td>
                </tr>
                <tr>
                  <td>Total de premiados por categoria</td>
                  <td class="text-center">35</td>
                  <td class="text-center">30</td>
                  <td class="text-center">20</td>
                  <td class="text-center">16</td>
                  <td class="text-center">101</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="awards_gradient awards_gradient-home">
      <div class="container">
        <p class="awards_legend">Premiação Etapa Final<br>
          <span>(gerentes e acompanhantes)</span></p>
        <div class="row">
          <div class="col-sm-5">
            <div class="d-flex align-items-center mb-3">
              <img class="awards_icon" src="assets/images/disney-icon.png" alt="disney">
              <h3 class="awards_subtitle">Disneyland<br>
                <span>de Tóquio</span></h3>
            </div>
          </div>
          <div class="col-sm-7" style="margin-bottom: -192px">
            <div class="awards_card" style="padding-bottom: 210px">
              <p class="home_text text-primary mb-4">
                Uma viagem ao Japão com visita a Disneyland de Tóquio.
              </p>
              <p class="mb-0 mt-4"><small>*Atividades e roteiro da viagem poderão sofrer ajustes sem aviso
                prévio</small></p>
            </div>
          </div>
        </div>
      </div>
      <div class="awards_slide">
        <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" id="slickSlider">
          <div ngxSlickItem *ngFor="let x of expedition;let i = index">
            <img class="awards_img" [ngStyle]="{'width':x.width}" src="assets/images/premiation-2/{{x.image}}"
                 alt="slide">
          </div>
        </ngx-slick-carousel>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-5">
            <p class="home_text pt-5 pb-3">
              A etapa final terá avaliação nacional dentro das quatro categorias (A, B, C e D), premiando da seguinte
              forma:
            </p>
            <dl class="home_list">
              <dt class="home_list_lbl">Categoria A</dt>
              <dd class="home_list_value">3 primeiras concessionárias</dd>
            </dl>
            <dl class="home_list">
              <dt class="home_list_lbl">Categoria B</dt>
              <dd class="home_list_value">2 primeiras concessionárias</dd>
            </dl>
            <dl class="home_list">
              <dt class="home_list_lbl">Categoria C</dt>
              <dd class="home_list_value">2 primeiras concessionárias</dd>
            </dl>
            <dl class="home_list">
              <dt class="home_list_lbl">Categoria D</dt>
              <dd class="home_list_value">2 primeiras concessionárias</dd>
            </dl>
          </div>
          <div class="col-sm-7">
            <div class="awards_card rounded-0">
              <div class="table-responsive">
                <table class="table table-secondary w-100 final">
                  <thead class="thead-dark">
                  <tr class="w-100 final-tr">
                    <th scope="col">Categorias</th>
                    <th class="text-center bottom-bar" scope="col">A</th>
                    <th class="text-center bottom-bar" scope="col">B</th>
                    <th class="text-center bottom-bar" scope="col">C</th>
                    <th class="text-center bottom-bar" scope="col">D</th>
                    <th class="text-center bottom-bar" scope="col"><strong>Total</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Quantidade de concessionárias premiadas</td>
                    <td class="text-center">3</td>
                    <td class="text-center">2</td>
                    <td class="text-center">2</td>
                    <td class="text-center">2</td>
                    <td class="text-center">9</td>
                  </tr>
                  <tr>
                    <td>Quantidade de gerentes premiados por concessionária</td>
                    <td class="text-center">2</td>
                    <td class="text-center">2</td>
                    <td class="text-center">2</td>
                    <td class="text-center">2</td>
                    <td class="text-center">8</td>
                  </tr>
                  <tr>
                    <td>Quantidade de funcionários premiados por concessionária</td>
                    <td class="text-center">2</td>
                    <td class="text-center">2</td>
                    <td class="text-center">2</td>
                    <td class="text-center">2</td>
                    <td class="text-center">8</td>
                  </tr>
                  <tr>
                    <td>Total de premiados por concessionária</td>
                    <td class="text-center">4</td>
                    <td class="text-center">4</td>
                    <td class="text-center">4</td>
                    <td class="text-center">4</td>
                    <td class="text-center">16</td>
                  </tr>
                  <tr>
                    <td>Total de premiados por categoria</td>
                    <td class="text-center">12</td>
                    <td class="text-center">8</td>
                    <td class="text-center">8</td>
                    <td class="text-center">8</td>
                    <td class="text-center">36</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="faq" class="faq">
  <div class="container">
    <h2 class="home_title"><span>Perguntas frequentes</span></h2>
    <div class="d-block">
      <div class="js-accordion">
        <div class="accordion">
          <div class="accordion-item  mb-5">
            <a href="#questao_1" data-toggle="collapse"
               class="faq_question  collapsed d-flex justify-content-between ">
              <span class="number">1</span>
              <div class="faq_question-content"><span
                class="p-3">Qual o período da campanha? </span>
                <div id="questao_1" class="collapse faq_anwser p-3">
                  <p>
                    Novembro de 2023 a Agosto de 2024.
                  </p>
                </div>
              </div>
              <span class="icon">
              <svg-icon src="assets/svg/drop.svg"></svg-icon>
            </span>
            </a>
          </div>

          <div class="accordion-item  mb-5">
            <a href="#questao_2" data-toggle="collapse"
               class="faq_question  collapsed d-flex justify-content-between ">
              <span class="number">2</span>
              <div class="faq_question-content"><span class="p-3">Quem participa? </span>
                <div id="questao_2" class="collapse faq_anwser p-3">
                  <p>
                    Todas as Concessionárias Volkswagen Automóveis com concessão plena, e PATs que tenham sido nomeados
                    até 30 de outubro de 2023.
                  </p>
                </div>
              </div>
              <span class="icon">
              <svg-icon src="assets/svg/drop.svg"></svg-icon>
            </span>
            </a>
          </div>

          <div class="accordion-item  mb-5">
            <a href="#questao_3" data-toggle="collapse"
               class="faq_question  collapsed d-flex justify-content-between ">
              <span class="number">3</span>
              <div class="faq_question-content"><span class="p-3">Quais são as categorias?</span>
                <div id="questao_3" class="collapse faq_anwser p-3">
                  <p>
                    As concessionárias concorrerão em 4 categorias, no âmbito nacional, de acordo com o seu objetivo de
                    compras validado no PAC Pós-Vendas 2023/2024 (Potencial Anual do Concessionário).
                  </p>
                </div>
              </div>
              <span class="icon">
              <svg-icon src="assets/svg/drop.svg"></svg-icon>
            </span>
            </a>
          </div>

          <div class="accordion-item  mb-5">
            <a href="#questao_4" data-toggle="collapse"
               class="faq_question  collapsed d-flex justify-content-between ">
              <span class="number">4</span>
              <div class="faq_question-content"><span class="p-3">Quais são os critérios de avaliação?</span>
                <div id="questao_4" class="collapse faq_anwser p-3">
                  <p>
                    Pontuação Mensal – Itens regulares
                  </p>
                  <img class="question-avaliation" src="assets/images/terms/table-1.png" alt=""/>
                </div>
              </div>
              <span class="icon">
              <svg-icon src="assets/svg/drop.svg"></svg-icon>
            </span>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
