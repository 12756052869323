<div class="partners">
  <section class="ts-background">
    <div class="partners_header pb-5">
      <div class="container">

        <div class="row align-items-start">
          <div class="col-md-6 col-12">
            <h1 class="partners_title">Parceiros</h1>
            <p class=" text-white">
              A seguir estão descritos os critérios e parâmetros de participação e pontuação, relativos a cada um dos
              parceiros e seus produtos indicados para a campanha.
            </p>
            <p class="text-white ">
              <strong>Campanha válida de NOVEMBRO 2023 a AGOSTO de 2024</strong>
            </p>

          </div>
          <div class="col-md-6 col-12">


            <a target="_blank" href="assets/files/Pontuacao-Parceiros-AdrenalinaVW.pdf"
               class="btn btn-secondary "><b>Download do Regulamento PDF</b></a>
            <br>

            <a *ngIf="handlerDetail()" class="btn btn-outline-white mb-2 mb-4 mt-4" href="javascript:void('');"
               [routerLink]="'/parceiros/detalhe'">Detalhe do Parceiro</a>

          </div>
        </div>
        <!--<div class="w-100 d-block text-right mb-5">
          <a target="_blank" href="assets/files/Pontuacao-Parceiros-AdrenalinaVW.pdf"
             class="btn btn-outline-white "><b>Download do Regulamento PDF</b></a>
        </div>-->
      </div>
    </div>
  </section>


  <section class="interozone">
    <div class="container py-5 awards_secondary">
      <div class="row">
        <div class="col-sm-4 d-flex justify-content-center">
          <img src="assets/images/partners-interozone.png" alt="interozone">
        </div>
        <div class="col-sm-7 offset-sm-1">
          <h2 class="awards_subtitle">Interozone</h2>
          <p class="home_text">
            <strong>
              As concessionárias serão pontuadas na compra do produto Interzone participantes da campanhas.
            </strong>
          </p>
          <div class="interozone_card">
            <span>CARTÃO AR | APR-EQ8-002-2</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-4">
          <span class="interozone_subtitle mt-4">
            A pontuação mensal terá como base a compra por objetivo do produto Interozone divido pelas categorias A, B, C e D da campanha Adrenalina, conforme tabela:
          </span>
        </div>
        <div class="col-sm-6 offset-sm-1">
          <div class="table-responsive mt-2">
            <table class="table table-secondary table-partners">
              <thead class="thead-dark">
              <tr>
                <th style="font-size: 25px" colspan="5"><strong>Volume de Cartões</strong></th>
              </tr>
              <tr>
                <th class="text-center" scope="col">A</th>
                <th class="text-center" scope="col">B</th>
                <th class="text-center" scope="col">C</th>
                <th class="text-center" scope="col">D</th>
                <th class="text-center" scope="col">Pontuação Mensal</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="text-center">100</td>
                <td class="text-center">80</td>
                <td class="text-center">60</td>
                <td class="text-center">40</td>
                <td class="text-center bg-white">50</td>
              </tr>
              <tr>
                <td class="text-center">120</td>
                <td class="text-center">100</td>
                <td class="text-center">80</td>
                <td class="text-center">60</td>
                <td class="text-center bg-white">75</td>
              </tr>
              <tr>
                <td class="text-center">140</td>
                <td class="text-center">120</td>
                <td class="text-center">100</td>
                <td class="text-center">80</td>
                <td class="text-center bg-white">100</td>
              </tr>
              <tr>
                <td class="text-center">160></td>
                <td class="text-center">140></td>
                <td class="text-center">120></td>
                <td class="text-center">100></td>
                <td class="text-center bg-white">200</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

  </section>

  <section class="tirreno">
    <div class="awards_gradient tirreno">
      <div class="container">
        <div class="row" style="row-gap: 30px">
          <div class="col-sm-4">
            <img src="assets/images/tirreno.png" alt="Tirreno">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th scope="col">Categoria</th>
                  <th class="text-secondary text-center" scope="col">Objetivo de Compras</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">A</td>
                  <td class="text-center bg-g">R$ 18.000,00</td>
                </tr>
                <tr>
                  <td class="text-center">B</td>
                  <td class="text-center bg-g">R$ 12.000,00</td>
                </tr>
                <tr>
                  <td class="text-center">C</td>
                  <td class="text-center bg-g">R$ 8.000,00</td>
                </tr>
                <tr>
                  <td class="text-center">D</td>
                  <td class="text-center bg-g">R$ 4.000,00</td>
                </tr>
                </tbody>
              </table>
            </div>
            <span class="tirreno_subtitle text-primary">
              Os pontos passam a ser atribuídos numa escala crescente a partir do cumprimento de 80% do objetivo Tirreno
              preestabelecido para cada concessionária, conforme tabela:
            </span>
          </div>
          <div class="col-sm-7 offset-sm-1">
            <h3 class="awards_subtitle">Tirreno</h3>
            <p class="home_text mb-4 text-primary">
              As concessionárias serão pontuadas na compra dos produtos Tirreno participantes da campanha conforme
              descrito abaixo.
            </p>
            <p class="home_text mb-4 text-primary">
              A pontuação mensal terá como base o percentual comparativo entre o valor dos pedidos colocados no mês dos
              produtos Tirreno avaliados na campanha e o valor do volume objetivado desses mesmos produtos para a
              concessionária.
            </p>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="text-center" scope="col">% Meta</th>
                  <th class="text-center text-secondary" scope="col">PONTUAÇÃO</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">De 0 a 79,99%</td>
                  <td class="text-center ">0</td>
                </tr>
                <tr>
                  <td class="text-center">De 80 a 84,99%</td>
                  <td class="text-center ">40</td>
                </tr>
                <tr>
                  <td class="text-center">De 85 a 89,99%</td>
                  <td class="text-center ">60</td>
                </tr>
                <tr>
                  <td class="text-center">De 90 a 94,99%</td>
                  <td class="text-center ">140</td>
                </tr>
                <tr>
                  <td class="text-center">De 95 a 99,99%</td>
                  <td class="text-center ">160</td>
                </tr>
                <tr>
                  <td class="text-center">100%</td>
                  <td class="text-center ">180</td>
                </tr>
                <tr>
                  <td class="text-center bg-g"><strong>Bônus (*)</strong></td>
                  <td class=" text-center bg-g">20</td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="">(*) Bônus: Para o cliente ganhar a pontuação bônus deverá conter obrigatoriamente em
              seu pedido o produto:
              1) Fluído para freio DOT 4LV 500ml (B 000 750 Q2), independente do % da meta que ele comprar.</p>
          </div>
          <div class="col-12">
            <div class="container">
              <div class="border-blue">
                <div class="row">
                  <div class="w-30">
                    <h2 class="campaign_description_white_secondary bg-primary">PRODUTOS TIRRENO AVALIADOS
                      NA CAMPANHA</h2>
                  </div>
                  <div class="w-70 bg-gray ">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class="thead-dark">
                        <tr>
                          <th class="w-30 text-secondary " scope="col">Código</th>
                          <th style="text-transform: unset" class="w-70  " scope="col">Descrição
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="w-30 ">G 12E 040 R2</td>
                          <td class="w-70 ">Aditivo de Radiador pronto uso G12evo 1L</td>
                        </tr>

                        <tr>
                          <td class="w-30 ">G JZZ 040 R3</td>
                          <td class="w-70 ">Aditivo de Radiador pronto uso G12evo 5L</td>
                        </tr>
                        <tr>
                          <td class="w-30 ">B 000 750 Q2</td>
                          <td class="w-70 ">Fluido para Freio DOT 4 LV 500ml</td>
                        </tr>
                        <tr>
                          <td class="w-30 ">G 052 164 R1</td>
                          <td class="w-70 ">Limpa para-brisas 200ml</td>
                        </tr>
                        <tr>
                          <td class="w-30 ">B JZZ 700 Q2</td>
                          <td class="w-70 ">Fluido para freio DOT 4 500ml</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="maxi py-2">
    <div class="container mt-5">
      <div class="row">
        <div class="col-sm-4 d-flex justify-content-end">
          <img src="assets/images/maxi-performance.png" alt="maxi">
        </div>
        <div class="col-sm-7 offset-sm-1">
          <h2 class="awards_subtitle ">Maxi </h2>
          <p class="home_text">
            A pontuação mensal terá como base o percentual comparativo entre o volume, em litros, dos pedidos colocados
            no mês e o volume mensal objetivado.
          </p>
          <p class="home_text">
            Para se ter elegibilidade da pontuação, o grupo econômico deve bater o alvo de óleo de moto;
          </p>
          <p class="home_text">
            <strong>Nota: são considerados na campanhas todos os óleos comercializados pela Volkswagen (litro ou tambor
              Maxi
              Performance).</strong>
          </p>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="blue-card maxi">
          <div class="row w-100 justify-content-between">
            <div class="col-lg-4 bg-campaign">
              <p class="campaign_description_white ">PRODUTOS MAXI PERFORMANCE AVALIADOS NA CAMPANHA</p>
            </div>
            <div class="col-lg-4">
              <div class="table-responsive maxi">

                <table class="table">
                  <thead class="thead-dark">
                  <tr>
                    <th class=" text-secondary text-center" scope="col">Linha</th>
                    <th class="text-secondary text-center" scope="col">PN</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class=" w-50 text-center">Volkswagen</td>
                    <td class="table-valor text-center ">G-S55-553-R2-BRA</td>
                  </tr>
                  <tr>
                    <td class=" w-50 text-center">Volkswagen</td>
                    <td class="table-valor text-center">G-S55-553-R6-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Volkswagen</td>
                    <td class="table-valor text-center">G-198-553</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Volkswagen</td>
                    <td class="table-valor text-center">G-S55-545-M2-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Volkswagen</td>
                    <td class="table-valor text-center">G-S55-545-M9-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Volkswagen</td>
                    <td class="table-valor text-center">G-S55-167-R7-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Volkswagen</td>
                    <td class="table-valor text-center">G-S60-577-M2-BRA</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-4 mt-2">
              <div class="table-responsive maxi">

                <table class="table">
                  <thead class="thead-dark">
                  <tr>
                    <th class=" text-secondary" scope="col">Linha</th>
                    <th class="text-secondary text-center" scope="col">PN</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="w-50 text-center">Economy</td>
                    <td class="table-valor text-center ">G-JZZ-502-M2-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Economy</td>
                    <td class="table-valor text-center">G-JZZ-502-M9-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Economy</td>
                    <td class="table-valor text-center">G-JZZ-107-32-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Economy</td>
                    <td class="table-valor text-center">G-JZZ-250-R2-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Economy</td>
                    <td class="table-valor text-center">G-JZZ-530-R2-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Economy</td>
                    <td class="table-valor text-center">G-JZZ-530-R9-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Economy</td>
                    <td class="table-valor text-center">G-JZZ-020-C2-BRA</td>
                  </tr>
                  <tr>
                    <td class="w-50 text-center">Economy</td>
                    <td class="table-valor text-center">G-JZZ-020-R9-BRA</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>


  <section class="wurth">
    <div class="container ">
      <div class="d-block">
        <div class="row ">
          <div class="mt-5 col-lg-4">
            <img class src="assets/images/wurthbig.png" alt="">

            <p class="campaign_description_secondary">A apuração do cumprimento
              do objetivo será de acordo com
              a escala abaixo:</p>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class=" w-70 text-secondary" scope="col">Percentual de cumprimento do objetivo</th>
                  <th class="text-primary text-center w-30" scope="col">Pontuação</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="bg-none w-70">De 0,00% a 89,99%</td>
                  <td class="table-valor text-center  w-30">0</td>
                </tr>
                <tr>
                  <td class="bg-none w-70">De 90,00% a 99,99%</td>
                  <td class="table-valor text-center w-30">140</td>
                </tr>
                <tr>
                  <td class="bg-none w-70">De 100,00% acima</td>
                  <td class="table-valor text-center w-30">180</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card-text">
              <p>
                <b>BÔNUS EXTRA MENSAL – 20 PONTOS:</b>
                Todos clientes que colocarem pedidos DSH de uma quantidade mínima de cada
                um dos produtos abaixo (X) durante o mês, terão direito a uma pontuação extra de 20 pontos.
              </p>
            </div>

          </div>
          <div class="mt-5 offset-lg-1 col-lg-7">
            <h3 class="campaign_subtitle mb-2">WURTH</h3>
            <p class="home_text">As concessionárias serão pontuadas nas compras dos produtos WURTH cadastrados na
              campanha.
              <br>
              <br>
              O objetivo das concessionárias são individuais e estarão disponíveis no site da campanha.
              <br>
              <br>
              A pontuação mensal terá como base o percentual comparativo entre o valor dos pedidos dos produtos da linha
              WURTH colocados via DSH no mês e o seu objetivo mensal de compras desses produtos.
              <br>
              <br>
            </p>
            <p class="campaign_description_secondary">Para clientes novos ou sem histórico de compras WURTH durante o
              ano passado os objetivos são estabelecidos do acordo com a categorização VW de acordo com a seguinte
              tabela:</p>
            <p class="text-center campaign_description_secondary mt-3"><strong>Objetivo mensal (R$) x Pontuação</strong>
            </p>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="table-title" scope="col">Categoria</th>
                  <th class="text-secondary text-center" scope="col">100 Pontos</th>
                  <th class="text-secondary text-center" scope="col">140 Pontos</th>
                  <th class="text-secondary text-center" scope="col">180 Pontos</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="bg-white text-center">A</td>
                  <td class="bg-none text-center table-valor">R$ 5.000,00</td>
                  <td class="bg-none text-center table-valor">R$ 10.000,00</td>
                  <td class="bg-none text-center table-valor">R$ 15.000,00</td>
                </tr>
                <tr>
                  <td class="bg-white text-center">B</td>
                  <td class="bg-none text-center table-valor">R$ 4.000,00</td>
                  <td class="bg-none text-center table-valor">R$ 7.500,00</td>
                  <td class="bg-none text-center table-valor">R$ 11.000,00</td>
                </tr>
                <tr>
                  <td class="bg-white text-center">C</td>
                  <td class="bg-none text-center table-valor">R$ 3.000,00</td>
                  <td class="bg-none text-center table-valor">R$ 5.500,00</td>
                  <td class="bg-none text-center table-valor">R$ 7.500,00</td>
                </tr>
                <tr>
                  <td class="bg-white text-center">D</td>
                  <td class="bg-none text-center table-valor">R$ 2.000,00</td>
                  <td class="bg-none text-center table-valor">R$ 3.000,00</td>
                  <td class="bg-none text-center table-valor">R$ 4.000,00</td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="home_text mt-4 mb-5">
              OBSERVAÇÃO: é necessária a compra de TODOS os produtos marcados abaixo (X) dentro do mesmo mês para
              garantir
              o BÔNUS EXTRA MENSAL durante o período da Campanha ADRENALINA, sendo que, para o produto DESCARBONIZANTE
              PRO,
              a concessionária pode optar pela compra de uma das duas embalagens disponíveis (300 ml ou 65 ml).
            </p>
          </div>
          <div class="col-lg-12">
            <div class="blue-card">
              <div class="row">
                <div class="col-lg-4">
                  <p class="campaign_description_white ">PRODUTOS WURTH QUE FAZEM PARTE DA CAMPANHA:</p>
                </div>
                <div class="offset-lg-1 col-lg-7">
                  <div class="table-responsive">
                    <table class="table p-3">
                      <thead class="thead-dark">
                      <tr>
                        <th class=" text-secondary text-center w-30 " scope="col">CÓDIGO</th>
                        <th class="  w-70" scope="col">Descrição</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="text-primary-strong w-30">APR-052-001-H</td>
                        <td class="table-valor w-70  ">DESCARBONIZANTE PRO 65ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-030</td>
                        <td class="table-valor w-70">DESCARBONIZANTE PRO 300ML/230G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-458</td>
                        <td class="table-valor w-70">DESENGRAXANTE EXPRESS 500ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">APR-096-300-C</td>
                        <td class="table-valor w-70">LIMPEZA DE HABITÁCULO SEM GÁS 80ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">G-001-770-A2</td>
                        <td class="table-valor w-70">ADITIVO COMBUSTÍVEL VW KEROPUR 3131 90ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-843</td>
                        <td class="table-valor w-70">PROTETOR DE POLO DE BATERIA WURTH</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-000-106</td>
                        <td class="table-valor w-70">ANTI CHIOS 65ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-604</td>
                        <td class="table-valor w-70">OLEO PNEUMATICO S10 500 ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-307</td>
                        <td class="table-valor w-70">HHS 2000 500 ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-303</td>
                        <td class="table-valor w-70">HHS 2000 200 ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-306</td>
                        <td class="table-valor w-70">HSW PRO3</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-165</td>
                        <td class="table-valor w-70">TRAVA ROSCAS TORQUE MEDIO W742 50 G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-162</td>
                        <td class="table-valor w-70">TRAVA ROSCAS TORQUE ALTO W777 10 G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-163</td>
                        <td class="table-valor w-70">TRAVA ROSCAS TORQUE ALTO W777 50 G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-334</td>
                        <td class="table-valor w-70">WURTH BONDER PLUS 20 G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-335</td>
                        <td class="table-valor w-70">WURTH BONDER PLUS 5 G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-335</td>
                        <td class="table-valor w-70">WURTH BONDER PLUS 5 G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-335</td>
                        <td class="table-valor w-70">WURTH BONDER PLUS 5 G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-330</td>
                        <td class="table-valor w-70">WATER OFF 100ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-285</td>
                        <td class="table-valor w-70">TIW 500 LIQUIDO P/ TESTE BICOS INJ 500ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-897</td>
                        <td class="table-valor w-70">REMOVEDOR DE JUNTAS 300ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-540</td>
                        <td class="table-valor w-70">MARCADOR INDUSTRIAL AMARELO 60 G</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">APR-052-001-AH</td>
                        <td class="table-valor w-70">LIMPA CONTATO 300ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-973</td>
                        <td class="table-valor w-70">ROST OFF WURTH 300ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-970</td>
                        <td class="table-valor w-70">ROST OFF WURTH 600ML</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-002-376</td>
                        <td class="table-valor w-70">JOGO PROTETOR AUTOMOTIVO INTERNO</td>
                      </tr>
                      <tr>
                        <td class="text-primary-strong w-30 ">WUR-001-989-A</td>
                        <td class="table-valor w-70">SANGRIA FREIO 2 RESERV.C.SEM.FIO</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="petropulus">
    <div class="container ">
      <div class="d-block">
        <div class="row ">
          <div class="mt-5 col-lg-4">
            <img class src="assets/images/petropolus.png" alt="">


            <div class="card-text">
              <p>
                <b>Exemplo</b>
                <br>
                Se o objetivo mensal for de R$ 5.000,00,
                o DN precisa alcançar os R$ 5.000,00,
                sendo que R$ 2.500,00 devem ser do item G-001-780-M3.
              </p>
            </div>
            <p class="campaign_description_secondary my-3"><strong>
              Segue abaixo o quadro
              com o escalonamento dos pontos:
            </strong></p>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class=" w-70 text-secondary" scope="col">Percentual de cumprimento do objetivo</th>
                  <th class="text-primary text-center w-30" scope="col">Pontuação</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-primary text-center w-70">De 0,00% a 84,99%</td>
                  <td class=" bg-g text-center w-30">0</td>
                </tr>
                <tr>
                  <td class=" text-center w-70">85% a 89,99%</td>
                  <td class=" bg-g text-center w-30">40</td>
                </tr>
                <tr>
                  <td class="text-center w-70">90% a 94,99%</td>
                  <td class=" bg-g text-center w-30">80</td>
                </tr>
                <tr>
                  <td class="text-center w-70">95% a 99,99%</td>
                  <td class="bg-g text-center w-30">110</td>
                </tr>
                <tr>
                  <td class="text-center w-70">100% acima</td>
                  <td class=" bg-g text-center w-30">150</td>
                </tr>
                <tr class="bg-secondary">
                  <td class="bg-secondary text-primary w-70">50% do Objetivo Fat. em
                    G-001-780 - Pontuação
                    Adicional
                  </td>
                  <td class="bg-secondary text-primary text-center w-30">50</td>
                </tr>
                </tbody>
              </table>
            </div>


          </div>
          <div class="mt-5 offset-lg-1 col-lg-7">
            <h3 class="campaign_subtitle mb-2">PETROPLUS | STP</h3>
            <p class="home_text">
              Os objetivos das concessionárias são individuais e estarão disponíveis no site da campanha.
              <br>
              <br>
              1  Serão atribuídos pontos escalonados de acordo com a porcentagem de atingimento do Objetivo Mensal em
              Pedidos colocados, podendo alcançar até 150 pontos.

              <br>
              <br>
              2  Os 50 pontos restantes devem ser atribuídos apenas para Distribuidores Nacionais (DN) que atingirem
              100% do objetivo proposto, sendo que, desse total, 50% deve ser no Item G-001-780-M3.
              <br>
              <br>
            </p>
            <div class="petropulus-table">
              <p class="petropulus-table-head">PRODUTOS PETROPLUS AVALIADOS NA CAMPANHA</p>
              <div class="table-responsive petropulus-table-body">
                <table class="table">
                  <thead class="thead-dark">
                  <tr>
                    <th class="table-title text-secondary" scope="col">Código</th>
                    <th class="text-primary " scope="col">Descrição</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-bold">APR-096-153</td>
                    <td class="text-normal">Espuma de Limpeza à seco</td>

                  </tr>
                  <tr>
                    <td class="text-bold">G-001-780-M3</td>
                    <td class="text-normal">New AW-AG2000 - Aditivo de Combustível - Flex</td>

                  </tr>
                  <tr>
                    <td class="text-bold">G-JZW-012-R2</td>
                    <td class="text-normal">Aditivo - Líquido de Refrigeração</td>

                  </tr>
                  <tr>
                    <td class="text-bold">STP-ST0-912</td>
                    <td class="text-normal">Throttle Body & Air Intake Cleaner</td>

                  </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
  </section>


  <!--DAQUI PRA BAIXO-->


  <section class="wurth">
    <div class="container ">
      <div class="d-block">
        <div class="row ">
          <div class="mt-5 col-12 mb-5">
            <div class="w-100 d-flex">
              <img class="mr-5" src="assets/images/brazzo-lg.png" alt="Brazzo"/>
              <h3 class="campaign_subtitle mb-2">BRAZZO</h3>
            </div>

            <p class="home_text">REGRAS BRAZZO</p>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                <tr>
                  <td class="text-primary text-center" valign="top"><strong>CATEGORIA</strong></td>
                  <td class="text-center" valign="top"><strong>OBJETIVO MENSAL</strong></td>
                  <td class="text-center bg-primary text-secondary" valign="middle" rowspan="5"><strong>100%<br/><br/><b
                    class="text-big">100</b> <br/><br/>Pontos</strong></td>
                  <td class="text-center bg-secondary" valign="middle" rowspan="5"><strong>110%<br/><br/> <b
                    class="text-big">150</b><br/><br/>Pontos</strong></td>
                  <td class="text-center bg-primary text-secondary" valign="middle" rowspan="5"><strong>120%<br/><br/><b
                    class="text-big">200</b><br/><br/>Pontos</strong></td>
                </tr>
                <tr>
                  <td class="text-primary text-center">A</td>
                  <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 15.000,00</strong></td>
                </tr>
                <tr>
                  <td class="text-primary text-center">B</td>
                  <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 12.000,00</strong></td>
                </tr>
                <tr>
                  <td class="text-primary text-center">C</td>
                  <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 9.500,00</strong></td>
                </tr>
                <tr>
                  <td class="text-primary text-center">D</td>
                  <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 6.500,00</strong></td>
                </tr>
                </tbody>
              </table>
            </div>

            <h3 class="campaign_subtitle mb-2 home_text mt-5">Itens sugeridos com pontuação</h3>
            <div class="row ">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-secondary"><strong>Código</strong></th>
                      <th class="text-primary"><strong>Descrição</strong></th>
                      <th class="text-primary text-center"><strong>Preço Revenda</strong></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>V04.010.024.C</td>
                      <td>Assento de transporte pequeno</td>
                      <td class="text-center bg-primary text-secondary">R$ 238,37</td>
                    </tr>
                    <tr>
                      <td>V04.010.024.D</td>
                      <td>Assento de transporte grande</td>
                      <td class="text-center bg-primary text-secondary">R$ 282,12</td>
                    </tr>
                    <tr>
                      <td>V04.010.024.F</td>
                      <td>Caixa de transporte Nº 01</td>
                      <td class="text-center bg-primary text-secondary">R$ 72,00</td>
                    </tr>
                    <tr>
                      <td>V04.010.024.G</td>
                      <td>Caixa de transporte Nº 02</td>
                      <td class="text-center bg-primary text-secondary">R$ 90,31</td>
                    </tr>
                    <tr>
                      <td>V04.010.024.H</td>
                      <td>Caixa de transporte Nº 03</td>
                      <td class="text-center bg-primary text-secondary">R$ 130,93</td>
                    </tr>
                    <tr>
                      <td>V04.010.024.J</td>
                      <td>Caixa de transporte Nº 04</td>
                      <td class="text-center bg-primary text-secondary">R$ 177,23</td>
                    </tr>
                    <tr>
                      <td>V04.010.025.H</td>
                      <td>Adaptador de cinto de segurança (P)</td>
                      <td class="text-center bg-primary text-secondary">R$ 25,74</td>
                    </tr>
                    <tr>
                      <td>V04.010.025.J</td>
                      <td>Adaptador de cinto de segurança (G)</td>
                      <td class="text-center bg-primary text-secondary">R$ 35,33</td>
                    </tr>
                    <tr>
                      <td>V04.010.025.K</td>
                      <td>Peitoral</td>
                      <td class="text-center bg-primary text-secondary">R$ 115,34</td>
                    </tr>
                    <tr>
                      <td>V04.010.026.A</td>
                      <td>Protetor de banco Basic</td>
                      <td class="text-center bg-primary text-secondary">R$ 223,06</td>
                    </tr>
                    <tr>
                      <td>V04.010.026.B</td>
                      <td>Protetor de banco Standard</td>
                      <td class="text-center bg-primary text-secondary">R$ 241.67</td>
                    </tr>
                    <tr>
                      <td>V04.010.026.C</td>
                      <td>Protetor de banco Standard Plus</td>
                      <td class="text-center bg-primary text-secondary">R$ 304,71</td>
                    </tr>
                    <tr>
                      <td>V04.010.029.K</td>
                      <td>Rede de proteção (P)</td>
                      <td class="text-center bg-primary text-secondary">R$ 53,27</td>
                    </tr>
                    <tr>
                      <td>V04.010.029.L</td>
                      <td>Rede de proteção com bolsos</td>
                      <td class="text-center bg-primary text-secondary">R$ 283,86</td>
                    </tr>
                    <tr>
                      <td>V04.010.029.M</td>
                      <td>Rede de proteção (G)</td>
                      <td class="text-center bg-primary text-secondary">R$ 125,50</td>
                    </tr>
                    <tr>
                      <td>V04.010.080.AP</td>
                      <td>Meia de proteção</td>
                      <td class="text-center bg-primary text-secondary">R$ 16,39</td>
                    </tr>

                    <tr>
                      <td>V04-010-057-L -041</td>
                      <td>Friso Bx Relev T-Cross - Preto Ninja</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    <tr>
                      <td>V04-010-057-L -A3X</td>
                      <td>Friso Bx Relev T-Cross - Vermelho Sunset</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    <tr>
                      <td>V04-010-057-L -B7S</td>
                      <td>Friso bx relev T-Cross - Prata Pyrit</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    <tr>
                      <td>V04-010-057-L -C9A</td>
                      <td>Friso bx relev T-Cross - Branco Puro</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>


                    <tr>
                      <td>V04-010-057-L -D7X</td>
                      <td>Friso bx relev T-Cross - Cinza Platinum</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    <tr>
                      <td>V04-010-057-L -E5M</td>
                      <td>Friso bx relev T-Cross - Azul Norway</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="table-header-mobile">
                    <tr>
                      <th class="text-secondary"><strong>Código</strong></th>
                      <th><strong>Descrição</strong></th>
                      <th class="text-center"><strong>Preço Revenda</strong></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>V04-010-057-M -041</td>
                      <td>Friso bx relev Virtus - Preto Ninja</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-M -B9A</td>
                      <td>Friso bx relev Virtus - Branco Cristal</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49
                      </td>
                    </tr>
                    <tr>
                      <td>V04-010-057-M -C5C</td>
                      <td>Friso bx relev Virtus - Azul Biscay</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-M -D7X</td>
                      <td>Friso bx relev Virtus - Cinza Platinum</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-M -E7Q</td>
                      <td>Friso bx relev Virtus - Prata Sirius</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-P -B7S</td>
                      <td>Friso bx relev Taos - Prata Pyrit</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-P -C5B</td>
                      <td>Friso bx relev Taos - Azul Atlantic</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49
                      </td>
                    </tr>
                    <tr>
                      <td>V04-010-057-P -C9A</td>
                      <td>Friso bx relev Taos - Branco Puro</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-P -C9X</td>
                      <td>Friso bx relev Taos - Preto Mystic</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-P -H1X</td>
                      <td>Friso bx relev Taos - Bege Majowe</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-P -R7H</td>
                      <td>Friso bx relev taos - Cinza Indium</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-Q -B7S</td>
                      <td>Friso bx relev Amarok - Prata Pyrit</td>
                      <td class="text-center bg-primary text-secondary">R$ 308,63</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-Q -B9A</td>
                      <td>Friso bx relev Amarok - Branco Cristal</td>
                      <td class="text-center bg-primary text-secondary">R$ 308,63</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-Q -C5B</td>
                      <td>Friso bx relev Amarok - Azul Atlantic</td>
                      <td class="text-center bg-primary text-secondary">R$ 308,63</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-Q -C9X</td>
                      <td>Friso bx relev Amarok - Preto Mystic</td>
                      <td class="text-center bg-primary text-secondary">R$ 308,63</td>
                    </tr>
                    <tr>
                      <td>V04-010-057-Q -R7H</td>
                      <td>Friso bx relev Amarok - Cinza Indium</td>
                      <td class="text-center bg-primary text-secondary">R$ 308,63</td>
                    </tr>

                    <tr>
                      <td>V04-011-035-J -A3X</td>
                      <td>Friso bx relev Polo pa - Vermelho Sunset</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    <tr>
                      <td>V04-011-035-J -B9A</td>
                      <td>Friso bx relev Polo pa - Branco Cristal</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    <tr>
                      <td>V04-011-035-J -D7X</td>
                      <td>Friso bx relev Polo pa - Cinza Platinum</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    <tr>
                      <td>V04-011-035-J -E7Q</td>
                      <td>Friso bx relev Polo pa - Prata Sirius</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>


                    <tr>
                      <td>V04-011-035-J-041</td>
                      <td>Friso bx relev Polo pa - Preto Ninja</td>
                      <td class="text-center bg-primary text-secondary">R$ 256,49</td>
                    </tr>

                    <tr>
                      <td>G-600-200-R2-BR1</td>
                      <td>Agente de Limpeza EA 211</td>
                      <td class="text-center bg-primary text-secondary">R$ 24,87</td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="bosch">
    <div class="container ">
      <div class="d-block">
        <div class="row ">
          <div class="mt-5 col-lg-4">
            <img class src="assets/images/bosh.png" alt="Bosch">
            <div class="card-text">
              <p class="text-white">
                A pontuação mensal terá como base o percentual comparativo entre o valor dos pedidos colocados no mês,
                dos produtos BOSCH avaliados na campanha, e o valor do volume objetivado desses mesmos produtos para a
                concessionária, com base no Sistema ASAS (Controle de Performance de Peças e Acessórios).
              </p>
            </div>
            <p class="mt-3">
              <b>Os pontos passam a ser atribuídos numa escala crescente a partir do atingimento de 90% do objetivo
                BOSCH pré-estabelecido para cada concessionária, conforme tabela a seguir:</b><br/><br/>

            </p>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="text-center text-secondary" scope="col">Percentual de cumprimento do objetivo</th>
                  <th class="text-center text-primary" scope="col">PONTUAÇÃO</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="bg-white text-center">De 0,00% a 89,99%</td>
                  <td class="text-center ">0</td>
                </tr>
                <tr>
                  <td class="bg-white text-center">90% a 109,99%</td>
                  <td class="text-center ">80</td>
                </tr>
                <tr>
                  <td class="bg-white text-center">110,00 % a 119,99%</td>
                  <td class="text-center ">140</td>
                </tr>
                <tr>
                  <td class="bg-white text-center">Acima de 120,00%</td>
                  <td class="text-center ">180</td>
                </tr>
                <tr class="bg-secondary">
                  <td class="text-center text-primary">Bônus*</td>
                  <td class="text-center text-primary">20</td>
                </tr>
                </tbody>
              </table>
              <p class="home_text">(*) Para ganhar o bônus mensal de 20 pontos, a concessionária deve cumprir no mínimo
                100% do
                objetivo BOSCH e, no mínimo, 10% do objetivo deve ser em produtos de lançamento de 2023 (NOVO)
                ou itens da linha ECONOMY.
                <br>
                <br>
              </p>
            </div>
          </div>
          <div class="mt-5 offset-lg-1 col-lg-7">
            <h3 class="campaign_subtitle mb-2">BOSCH</h3>

            <p class="home_text">As concessionárias serão pontuadas durante a campanha na compra dos seguintes
              produtos:</p>

            <div class="table-responsive petropulus-table-body b-20">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="table-title text-secondary" scope="col">Código</th>
                  <th class="text-primary " scope="col">Descrição</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-bold">03L-130-755-AB</td>
                  <td class="text-normal">Bomba de Pistão Radial</td>

                </tr>
                <tr>
                  <td class="text-bold">03L-130-755-R</td>
                  <td class="text-normal">Bomba de Pistão Radial</td>

                </tr>
                <tr>
                  <td class="text-bold">03L-130-277-Q</td>
                  <td class="text-normal">Injetor</td>

                </tr>
                <tr>
                  <td class="text-bold">026-905-409-A</td>
                  <td class="text-normal">Cabo de Ignição</td>

                </tr>

                <tr>
                  <td class="text-bold">032-905-409-B</td>
                  <td class="text-normal">Cabo de Ignição</td>

                </tr>

                <tr>
                  <td class="text-bold">030-905-409-G</td>
                  <td class="text-normal">Cabo de Ignição</td>

                </tr>

                <tr>
                  <td class="text-bold">032-906-031-R</td>
                  <td class="text-normal">Válvula de Injeção</td>

                </tr>
                </tbody>
              </table>
            </div>

            <h3 class="campaign_subtitle mt-5 mb-3">LINHA ECONOMY | <span class="text-secondary">NOVO</span></h3>

            <div class="table-responsive petropulus-table-body b-20">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="table-title text-secondary" scope="col">Código</th>
                  <th class="text-primary " scope="col">Descrição</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-bold">JZZ-905-106</td>
                  <td class="text-normal">Bobina de Ignição</td>

                </tr>
                <tr>
                  <td class="text-bold">JZZ-905-105-B</td>
                  <td class="text-normal">Bobina de Ignição</td>

                </tr>
                <tr>
                  <td class="text-bold">JZZ-905-603</td>
                  <td class="text-normal">Vela de Ignição</td>

                </tr>
                <tr>
                  <td class="text-bold">JZZ-998-051</td>
                  <td class="text-normal">Jogo de reparo para Bomba de Combustível</td>

                </tr>
                </tbody>
              </table>
            </div>

            <h3 class="campaign_subtitle mt-5 mb-3">ACESSÓRIOS | <span class="text-secondary">NOVO</span></h3>

            <div class="table-responsive petropulus-table-body b-20 mb-3">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="table-title text-secondary" scope="col">Código</th>
                  <th class="text-primary " scope="col">Descrição</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-bold">V04-010-036</td>
                  <td class="text-normal">Lâmpadas - H7 12V 55W Pure Light (Cx1Un)</td>

                </tr>
                <tr>
                  <td class="text-bold">V04-010-036-B</td>
                  <td class="text-normal">Lâmpadas - H4 12V 60/55W Pure Light (Cx1Un)</td>

                </tr>
                <tr>
                  <td class="text-bold">V04-010-036-C</td>
                  <td class="text-normal">Lâmpadas - H11 12V 55W Pure Light (Cx1Un)</td>

                </tr>
                <tr>
                  <td class="text-bold">V04-010-036-D</td>
                  <td class="text-normal">Lâmpadas - H1 12V 55W Pure Light (Cx1Un)</td>

                </tr>

                <tr>
                  <td class="text-bold">V04-010-036-E</td>
                  <td class="text-normal">Lâmpadas - W5W 12V 5W Pure Light (Cx10Un)</td>

                </tr>

                <tr>
                  <td class="text-bold">V04-010-036-F</td>
                  <td class="text-normal">Lâmpadas - P21/5W 12V 21/5W Pure Light (Cx10Un)</td>

                </tr>

                <tr>
                  <td class="text-bold">V04-010-036-G</td>
                  <td class="text-normal">Lâmpadas - P21W 12V 21W BA15S Pure Light (Cx10Un)</td>

                </tr>
                <tr>
                  <td class="text-bold">V04-010-036-H</td>
                  <td class="text-normal">Lâmpadas - H3 12v 55W Pure Light (Cx1Un)</td>

                </tr>
                <tr>
                  <td class="text-bold">V04-010-036-J</td>
                  <td class="text-normal">Lâmpadas - Hb4 (9006) 12V 55w Pure Light (Cx1Un)</td>

                </tr>
                <tr>
                  <td class="text-bold">V04-010-036-A</td>
                  <td class="text-normal">Lâmpadas - H8 12V 35W Pure Light (Cx1Un)</td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="fidelity">
    <div class="fidelity-content">
      <h3>Programa de <strong>Fidelidade E<span class="text-red">X</span>TRA</strong></h3>
      <div class="add-btn">
        <svg-icon class="mr-4" src="assets/svg/add.svg"></svg-icon>
        <span>Para cada <strong>R$ 2,00 =</strong></span>
        <p class="text-white mb-0 ml-3">1 ponto</p>
      </div>
      <p>*exceto produtos da linha diesel</p>
    </div>

  </section>


</div>
