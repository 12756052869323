import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CouponService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  checkDealer(model) {
    const url = `${this.BASE_URL}/coupon/check-dealer`;
    return this.http.post(url, model);
  }

  createCoupon(model) {
    const url = `${this.BASE_URL}/coupon`;
    return this.http.post(url, model);
  }

  getCoupons() {
    const url = `${this.BASE_URL}/coupon/me`;
    return this.http.get(url);
  }

  getLuckyNumbers() {
    const url = `${this.BASE_URL}/coupon/me/lucky-numbers`;
    return this.http.get(url);
  }
}
