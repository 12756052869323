import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {LuckyResult} from '../../../model/coupon/lucky';

declare var $: any;

@Component({
  selector: 'app-modal-lucky-number',
  templateUrl: './modal-lucky-number.component.html',
  styleUrls: ['./modal-lucky-number.component.scss']
})
export class ModalLuckyNumberComponent extends BaseComponent implements OnInit {

  @Input() arrList: LuckyResult[];

  constructor(public router: Router) {
    super(router);
  }

  ngOnInit(): void {
  }


}
