<div class="login">
  <img class="login_bg_tokyo" src="assets/images/tokyo.png" alt="logo"/>
  <img class="login_bg_beto" src="assets/images/beto.png" alt="logo"/>
  <div class="login_content">
    <img src="assets/images/logo.png" alt="">
    <h3 class="login-title">LOGIN </h3>
    <ng-container class="div" *ngIf="isLoginPanel">
      <div class="login_content-info">

        <fieldset class="form-primary">
          <div class="input-material-secondary">
            <input type="text" required="required" name="promoLoginCPF"
                   [(ngModel)]="model.cnpj" maxlength="20" [mask]="'00.000.000/0000-00'"
                   [dropSpecialCharacters]="false"/>
            <label>CNPJ</label>
          </div>
          <div class="input-material-secondary">
            <input type="password" required="required"
                   (keyup.enter)="onLogin()"
                   [(ngModel)]="model.password"
                   id="password"
                   name="password"
                   maxlength="50"/>
            <label>Senha</label>
            <div href="javascript:void(0)" class="btn-password-view" (click)="handlerViewPassword()">
              <svg-icon
                [src]="!handlerViewPasswordIcon() ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
            </div>
          </div>
          <a class="password" (click)="isLoginPanel = false">
            Esqueci minha Senha
          </a>
          <div class="d-block my-4">
            <a href="javascript:void('');" (click)="onLogin()" class="btn btn-block btn-secondary">ENTRAR</a>
          </div>
        </fieldset>
      </div>
    </ng-container>


    <ng-container *ngIf="!isLoginPanel">
      <div class="login_content-info">
        <h2>Esqueci minha senha</h2>
        <fieldset class="form-primary">
          <p>Digite o CNPJ cadastrado.</p>
          <div class="input-material-secondary">
            <input type="text" required="required"
                   [(ngModel)]="model.cnpj" [mask]="'00.000.000/0000-00'"
                   [dropSpecialCharacters]="false"/>
            <label>CNPJ</label>
          </div>
          <!--<div class="group text-right">
            <a href="javascript:void('');" class="form-link"
               (click)="isLoginPanel = true">Voltar</a>
          </div>-->
          <div class="group">
            <div class="row mt-5 mb-4">
              <div class="col-12 col-sm-6">
                <a href="javascript:void('');" (click)="isLoginPanel = true"
                   class="btn btn-block btn-outline-white">voltar</a>
              </div>
              <div class="col-12 col-sm-6">
                <a href="javascript:void('');" (click)="onForgot(model.cnpj)"
                   class="btn btn-block btn-secondary">ENVIAR</a>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

    </ng-container>

    <div class="copyright">
      Copyright © 2024 Todos os direitos reservados. Este site ou qualquer parte dele não pode ser reproduzido ou usado
      de forma alguma sem autorização expressa, por escrito, do autor ou editor. Para mais informações
      contato@vwadrenalina24.com.br.
    </div>
    <div class="contact">
      Fale conosco: <a href="mailto:contato@vwadrenalina24.com.br">contato@vwadrenalina24.com.br</a>
    </div>

  </div>
</div>
