import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {EventBusService} from '../../service/event-bus.service';
import {UserService} from '../../service/user.service';
import {trigger, state, style, animate, transition} from '@angular/animations';

declare var $: any;
declare var simpleParallax: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('scrollAnimation', [
      state('start', style({
        transform: 'translateY(0)'
      })),
      state('end', style({
        transform: 'translateY({{ yOffset }}px)'
      }), {params: {yOffset: '100'}}),
      transition('start => end', animate('500ms ease-in-out'))
    ])
  ]
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

  instance: any;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    variableWidth: true,
  };
  cruise: any[] = [
    {image: 'slide-img-1.png', width: '500px'},
    {image: 'slide-img-2.png', width: '288px'},
    {image: 'slide-img-3.png', width: '500px'},
    {image: 'slide-img-4.png', width: '500px'},
  ];


  expedition: any[] = [
    {image: 'slide-img-1.png', width: '500px'},
    {image: 'slide-img-2.png', width: '288px'},
    {image: 'slide-img-3.png', width: '500px'},
    {image: 'slide-img-4.png', width: '500px'},
    {image: 'slide-img-5.png', width: '500px'},
  ];

  scrollState = ''

  constructor(public router: Router,
              private renderer: Renderer2,
              private el: ElementRef,
              public userService: UserService) {
    super(router);
  }

  ngOnDestroy() {
    $('.bg-montain').css('background-color', 'initial');
    // this.instance.destroy();
  }

  scrollDown() {
    const element = this.el.nativeElement.querySelector('#yourTargetElementId'); // Replace with your actual element ID
    const yOffset = 100; // Adjust the scroll distance as needed

    if (element) {
      this.renderer.setStyle(document.documentElement, 'scrollBehavior', 'smooth');
      this.scrollState = 'end';
      setTimeout(() => {
        this.renderer.setStyle(document.documentElement, 'scrollBehavior', 'auto');
        this.scrollState = 'start';
        this.renderer.setProperty(document.documentElement, 'scrollTop', element.offsetTop - yOffset);
      }, 100); // Adjust the timeout to match the animation duration
    }
  }


  ngOnInit(): void {
    $('.bg-montain').css('background-color', '#fff');
    this.toggleLoader(false);
    const image = document.getElementsByClassName('thumbnail');
    this.instance = new simpleParallax(image, {
      delay: .9,
      transition: 'cubic-bezier(0,0,0,1)'
    });

    this.showMessage('AGUARDEM!', 'O RESULTADO DA ETAPA FINAL DA<br/>CAMPANHA ADRENALINA 2024 SERÁ<br/>DIVULGADO <b>DIA 11/10 NO PVEX.</b>', 'warning');
  }

}
