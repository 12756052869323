import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {RegisterComponent} from './views/register/register.component';
import {ContactComponent} from './views/contact/contact.component';
import {WinnersComponent} from './views/winners/winners.component';
import {TermsComponent} from './views/terms/terms.component';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {LoginComponent} from './views/login/login.component';
import {AuthGuard} from './service/auth-guard.service';
import {PartnersComponent} from './views/partners/partners.component';
import {RankingComponent} from './views/ranking/ranking.component';
import {PartnersDetailComponent} from './views/partners-detail/partners-detail.component';
import {SoonComponent} from "./views/soon/soon.component";


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'recuperar/:id', component: RecoveryComponent},
  {path: 'cadastro', component: RegisterComponent},
  {path: 'regulamento', component: TermsComponent},
  {
    path: '', component: MainComponent,
    children: [
      {path: 'editar', component: RegisterComponent},
      {path: 'ganhadores', component: WinnersComponent},
      {path: 'parceiros', component: PartnersComponent},
      {path: 'parceiros/:id', component: PartnersDetailComponent},
      {path: 'fale-conosco', component: ContactComponent},
      {path: 'ranking', component: RankingComponent},
      {path: 'soon', component: SoonComponent},
      {path: ':id', component: HomeComponent},
      {path: '', component: HomeComponent}
    ],
    canActivate: [AuthGuard]
  }
];

// {path: 'cadastrar-cupom', component: RegisterCouponComponent},

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
