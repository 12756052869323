import {City} from './city';

export class User {
  cpf: string;
  id: string;
  dn: string;
  cnpj: string;
  name: string;
  fantasyName: string;
  companyName: string;
  profile: string;
  birthday: Date;
  sex: string;
  phone: string;
  cellPhone: string;
  email: string;
  zipcode: string;
  address: string;
  number: string;
  complement: string;
  district: string;
  city: City;
  password: string;
  passwordConfirm: string;
  isAccept: boolean;
  isAcceptLgpd: boolean;
  isValidated: boolean;
  isReceiveInfo: boolean;
  category: string;

  constructor() {
    this.cpf = '';
    this.phone = '';
    this.cellPhone = '';
    this.birthday = null;
    this.zipcode = '';
    this.city = new City();
  }
}

