import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import * as moment from 'moment';

declare var $: any;

export class BaseComponent {
  chartWidth = 0;

  chartCategory = {
    type: 'LineChart',
    columnNames: ['', ''],
    data: [
      ['', 0],
    ],
    options: {
      legend: {position: 'bottom', alignment: 'start'},
      chartArea: {
        height: '1000px',
        top: 30,
        bottom: 70,
      },
    }
  };

  constructor(public router: Router) {
  }

  getUser() {
    return localStorage.getItem('token');
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  resizeCharts(cssCLass = '.js-chart-box') {
    let resizeTimeout;
    $(() => {
      if (this.isNullOrUndefined($(cssCLass)[0])) { return; }
      this.chartWidth = $(cssCLass)[0].getBoundingClientRect().width - 30;
      $(window).on('resize', () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          if (this.isNullOrUndefined($(cssCLass)[0])) { return; }
          this.chartWidth = $(cssCLass)[0].getBoundingClientRect().width - 30;
        }, 100);
      });
    });
  }

  showMessage(t: string, m: string, type: any = null) {
    Swal.fire({
      title: t,
      html: m,
      icon: type,
      confirmButtonColor: '#265ba3',
    });
  }

  confirmMessage(t: string, m: string, callback: any = null) {
    Swal.fire({
      title: t,
      html: m,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#265ba3',
      cancelButtonColor: '#dc3545'
    }).then((result) => {
      if (!this.isNullOrUndefined(result.value) && result.value === true) {
        if (callback != null) {
          callback();
        }
      }
    });
  }

  onError(error) {

    if (error.status === 401) {
      this.router.navigate(['/login']);
      return;
    }
    // const e = JSON.parse(error['error']);
    if (error.status === 400) {
      this.showMessage('Atenção', error.error.message, 'warning');
      return;
    }
    if (error.status === 500) {
      this.showMessage('Atenção', error.error.message, 'warning');
      return;
    }
  }

  isNullOrUndefined(value) {
    return typeof value === 'undefined' || value == null || value.length === 0;
  }

  validateField(obj) {
    let strError = ``;
    obj.map((e) => {
      if (this.isNullOrUndefined(e.value)) {
        strError += `${e.text}`;
      }
    });
    return strError;
  }

  isEMailValid(strEmail) {
    const str = strEmail;
    const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
    const testResult = exp.test(str);
    if (!testResult) {
      this.showMessage('Atenção', 'Informe um e-mail válido para prosseguir.', 'warning');
    }
    return testResult;
  }

  isCPFValid(strCPF) {
    let Soma;
    let Resto;
    Soma = 0;
    const strErrorTitle = 'Atenção';
    const strErrorDescription = 'Informe um CPF válido para prosseguir.';
    strCPF = strCPF.replace(/\D+/g, '');
    if (strCPF === '00000000000' || strCPF === '12112112155') {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    for (let i = 1; i <= 9; i++) {
      // tslint:disable-next-line:radix
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }
    // tslint:disable-next-line:radix
    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      // tslint:disable-next-line:radix
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }
    // tslint:disable-next-line:radix
    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    return true;
  }

  isCNPJValid(cnpj) {
    let i;
    const strErrorTitle = 'Atenção';
    const strErrorDescription = 'Informe um CNPJ válido para prosseguir.';
    cnpj = cnpj.replace(/[^\d]+/g, '');

    /*if (cnpj === '') {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }*/

    if (cnpj.length !== 14) {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999') {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }

    // Valida DVs
    const tamanho = cnpj.length - 2;
    const numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    const resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== Number(digitos.charAt(0))) {
      console.log('1');
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }

    return true;
  }

  dateOfBirthDateValidate(p: any) {
    // p = (p instanceof Date) ? p : moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
    if (p instanceof Date) {
      p = moment(p).format('DD/MM/yyyy');
    } else if (p.indexOf('T') >= 0) {
      p = moment(p.split('T')[0], 'yyyy-MM-DD').format('DD/MM/yyyy');
    } else if (p.indexOf('/') >= 0 || p.indexOf('-') >= 0) {
      p = moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
    } else if (!this.isNullOrUndefined(p) && p.indexOf('/') < 0) {
      p = moment(p).format('DD/MM/yyyy');
    }
    const dateFormatMin = moment().subtract(18, 'year');
    const dateFormatMax = moment().subtract(120, 'year');
    const dateFormatCurrent = moment();
    let valReturn = true;
    if (this.isNullOrUndefined(p) || p === 'Invalid date') {
      this.showMessage('Atenção', 'Preencha a data de nascimento corretamente.', 'warning');
      valReturn = false;
    } else if (!moment(p, 'DD/MM/YYYY', true).isValid()) {
      this.showMessage('Atenção', 'Preencha a data de nascimento corretamente.', 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') > dateFormatCurrent) {
      this.showMessage('Atenção', 'Preencha a data de nascimento corretamente.', 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') > dateFormatMin) {
      this.showMessage('Atenção', 'Não é permitido cadastro de menores de 18 anos.', 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') < dateFormatMax) {
      this.showMessage('Atenção', 'Não é permitido cadastro de maiores de 120 anos.', 'warning');
      valReturn = false;
    }
    return valReturn;
  }

  dateOfPromotionDateValidate(p: any) {
    // p = (p instanceof Date) ? p : moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
    if (p instanceof Date) {
      p = moment(p).format('DD/MM/yyyy');
    } else if (p.indexOf('/') >= 0 || p.indexOf('-') >= 0) {
      p = moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
    } else if (!this.isNullOrUndefined(p) && p.indexOf('/') < 0) {
      p = moment(p).format('DD/MM/yyyy');
    }
    const dateFormatMin = moment('01/11/2021', 'DD/MM/yyyy');
    const dateFormatMax = moment('31/01/2022', 'DD/MM/yyyy');
    const dateFormatCurrent = moment();
    let valReturn = true;
    const msgAlert = 'Data da emissão fora do período de participação.';
    if (this.isNullOrUndefined(p) || p === 'Invalid date') {
      this.showMessage('Atenção', msgAlert, 'warning');
      valReturn = false;
    } else if (!moment(p, 'DD/MM/YYYY', true).isValid()) {
      this.showMessage('Atenção', msgAlert, 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') < dateFormatMin) {
      this.showMessage('Atenção', msgAlert, 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') > dateFormatMax) {
      this.showMessage('Atenção', msgAlert, 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') > dateFormatCurrent) {
      this.showMessage('Atenção', 'Não é possível cadastrar notas com datas futuras.', 'warning');
      valReturn = false;
    }
    return valReturn;
  }

  toggleLoader(b: boolean): void {
    if (!b) {
      $('#loaderBox').fadeOut();
    } else {
      $('#loaderBox').fadeIn();
    }
  }

  handlerViewPassword(id = 'password') {
    const input = document.querySelector('#' + id);
    if (input.getAttribute('type') === 'password') {
      input.setAttribute('type', 'text');
    } else {
      input.setAttribute('type', 'password');
    }
  }

  handlerViewPasswordIcon(id = 'password') {
    // const input = document.querySelector('#password');
    const input = document.querySelector('#' + id);
    return input.getAttribute('type') === 'password';
  }

  goToInto(s: string, singlePage: boolean) {
    // if (singlePage) { this.isSinglePage = true; }
    this.toggleLoader(true);
    $('html, body').animate({scrollTop: 0}, 1000);
    setTimeout(() => {
      this.router.navigate([s]);
      setTimeout(() => {
        this.toggleLoader(false);
      }, 800);
    }, 800);
  }

  handlerCouponType(t: number) {
    switch (t) {
      case 1:
        return {id: 1, typeName: 'Serviço'};
        break;
      case 2:
        return {id: 2, typeName: 'Peças'};
        break;
      case 3:
        return {id: 3, typeName: 'Serviços e Peças'};
        break;
    }
  }

  downloadHandlerFile(e, fileName) {
    const downLoad = $('#btnDownload');
    downLoad.attr('href', e);
    downLoad.attr('download', fileName + '_' + moment().format('DD-MM-YYYY'));
    setTimeout(() => {
      $('#btnDownload')[0].click();
    });
  }

  numberWithCommas(x = 0) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let partsFormat = parts.join(',');
    partsFormat = partsFormat.replace('.', '.');
    return partsFormat;
  }

}
