import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {User} from '../../model/user/user';
import {UserService} from '../../service/user.service';
import {PartnerService} from '../../service/partner.service';
import {PartnerDashboard, PartnerFilter, PartnerList} from '../../model/partner/partner';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {PartnerRakingFilter, PartnerRakingList, PartnerRakingResult} from '../../model/partner/partner-raking';

declare var $;

@Component({
  selector: 'app-partners-detail',
  templateUrl: './partners-detail.component.html',
  styleUrls: ['./partners-detail.component.scss']
})
export class PartnersDetailComponent extends BaseComponent implements OnInit, OnDestroy {

  model: User = new User();
  provider = 'maxi'; // maxi|interzone|tirreno|wurth|bosch|brazzo


  modelRakingFilter: PartnerRakingFilter = new PartnerRakingFilter();
  modelRankingResult: PartnerRakingList = new PartnerRakingList();

  modelFilter: PartnerFilter = new PartnerFilter();
  modelResult: PartnerList = new PartnerList();

  modelChartResult: PartnerDashboard[] = [];

  currentRakingPage = 1;
  currentPage = 1;
  labels: any = {
    previousLabel: ' ',
    nextLabel: ' ',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };
  dropDisabled = false;
  list1: PartnerRakingResult[] = [];
  list2: PartnerRakingResult[] = [];

  constructor(public router: Router,
              public userService: UserService,
              public partnerService: PartnerService) {
    super(router);
  }

  ngOnDestroy(): void {
    $('.bg-montain').css('background-color', 'inital');
  }

  ngOnInit(): void {
    $('.bg-montain').css('background-color', '#E4E4E4');
    this.modelFilter.partner = 'maxi';
    this.modelRakingFilter.dn = 'maxi';
    this.modelRakingFilter.month = (moment().month() + 1).toString();
    this.userService.user.subscribe({
      next: data => {
        this.model = data as User;
        this.provider = this.model.profile === 'master' ? 'maxi' : this.model.companyName;
        this.modelFilter.partner = this.provider;
        this.getDashboard(this.provider);
      }
    });
    $('.header').addClass('bg-primary');

    this.actionRakingSearch();
    this.actionSearch();
  }

  pageRakingChanged(event) {
    this.currentRakingPage = event;
    this.actionRakingSearch();
  }

  pageChanged(event) {
    this.currentPage = event;
    this.actionSearch();
  }

  getDashboard(event: any) {
    //console.log('enviar provider para rota de dashboard: ' + this.provider);
    this.modelFilter.partner = event;
    this.modelRakingFilter.dn = event;
    this.partnerService.getPartnerDashboard('?partner=' + this.provider).subscribe({
      next: data => {
        this.modelChartResult = data as PartnerDashboard[];

        if (this.modelChartResult.length === 0) {
          this.chartCategory.columnNames = ['', ''];
          this.chartCategory.data = [['', 0]];
          return;
        }

        const arrData = [];
        this.chartCategory.columnNames = ['Data', 'Pontos'];

        this.modelChartResult.map((x) => {
          const e = [x.date, x.total_pontos];
          arrData.push(e);
        });

        this.chartCategory.data = arrData;

      }, error: err => super.onError(err)
    });
  }

  actionRakingSearch() {
    let a = '?page=' + this.currentRakingPage;

    if (!this.isNullOrUndefined(this.modelRakingFilter.userDn)) {
      a += '&userDn=' + this.modelRakingFilter.userDn;
    }
    if (!this.isNullOrUndefined(this.modelRakingFilter.month) && this.modelRakingFilter.month !== 'undefined') {
      a += '&month=' + this.modelRakingFilter.month;
    }
    if (!this.isNullOrUndefined(this.modelRakingFilter.category) && this.modelRakingFilter.category !== 'undefined') {
      a += '&category=' + this.modelRakingFilter.category;
    }
    if (!this.isNullOrUndefined(this.modelRakingFilter.dn) && this.modelRakingFilter.dn !== 'undefined') {
      a += '&dn=' + this.modelRakingFilter.dn;
    }

    this.partnerService.getRankingPage(a).subscribe({
      next: data => {
        this.modelRankingResult = data as PartnerRakingList;
        this.list1 = this.modelRankingResult.results.slice(0, 5);
        this.list2 = this.modelRankingResult.results.slice(5, 10);
      }, error: err => super.onError(err)
    });
  }

  actionSearch() {
    let a = '?page=' + this.currentPage;
    if (!this.isNullOrUndefined(this.modelFilter.partner)) {
      a += '&partner=' + this.modelFilter.partner;
    }
    if (!this.isNullOrUndefined(this.modelFilter.dn)) {
      a += '&dn=' + this.modelFilter.dn;
    }
    if (!this.isNullOrUndefined(this.modelFilter.category) && this.modelFilter.category !== 'undefined') {
      a += '&category=' + this.modelFilter.category;
    }
    if (!this.isNullOrUndefined(this.modelFilter.month) && this.modelFilter.month !== 'undefined') {
      a += '&month=' + this.modelFilter.month;
    }
    this.partnerService.getPartnerPage(a).subscribe({
      next: data => {
        this.modelResult = data as PartnerList;
      }, error: err => super.onError(err)
    });
  }

  getExcel() {
    const token = localStorage.getItem('token') as string;
    let url = `${environment.base_url}/partner/excel?token=${token}`;

    if (!super.isNullOrUndefined(this.modelFilter.partner)) {
      url += ('&partner=' + this.modelFilter.partner);
    }
    if (!super.isNullOrUndefined(this.modelFilter.month) && this.modelFilter.month !== 'undefined') {
      url += ('&month=' + this.modelFilter.month);
    }
    if (!super.isNullOrUndefined(this.modelFilter.category) && this.modelFilter.category !== 'undefined') {
      url += ('&category=' + this.modelFilter.category);
    }
    window.open(encodeURI(url));
  }

}
