import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {EventBusService, Events} from '../../service/event-bus.service';
import {UserService} from '../../service/user.service';
import {Subscription} from 'rxjs';
import {User} from '../../model/user/user';
import {ResponseServer} from '../../model/responseServer';
import {Token} from '../../model/user/token';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {


  visibility = true;
  model: User = new User();
  eventPageSingle: Subscription;
  isSinglePage = false;
  isLoginPanel = true;
  isNewPassword = false;

  constructor(public router: Router,
              private eventbus: EventBusService,
              public userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
    this.eventPageSingle = this.eventbus.on(Events.pageSingle, (data) => this.isSinglePage = data);
  }

  ngAfterViewInit(): void {
    this.configureVideoElementForAutoplay();
  }

  ngOnDestroy() {
    if (!this.eventPageSingle) { return; }
    this.eventPageSingle.unsubscribe();
  }

  onForgot(p: string) {
    if (p.length === 0) {
      super.showMessage('Informe o CNPJ cadastrado para recuperar a senha.', 'Atenção', 'error');
      return;
    }
    // if (!this.isCPFValid(p)) {
    //   return;
    // }
    const a: any = {cnpj: p};
    this.userService.forgot(a).subscribe(
      data => {
        const result = data as ResponseServer;
        if (result.error) {
        } else {
          $('#modalLogin').modal('hide');
          super.showMessage(result.message, 'Sucesso', 'success');
        }
        return true;
      },
      error => this.onError(error)
    );
  }

  onLogin() {
    console.log('onLogin');
    const arrValidateFields = [
      {value: this.model.cnpj, text: 'CNPJ*,<br>'},
      {value: this.model.password, text: 'Senha*.<br>'},
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
      return;
    }
    // if (!this.isCPFValid(this.model.cpf)) {
    //   return;
    // }
    this.userService.getAuthenticate(this.model).subscribe({
      next: data => this.saveLocalUser(data)
      , error: err => super.onError(err)
    });
  }

  saveLocalUser(data) {
    const token = data as Token;
    this.setToken(token.authorizationToken);
    this.userService.updateUserData(token.user);
    this.userService.checkAuthenticated();
    $('#modalLogin').modal('hide');
    this.router.navigate(['/']).then();
  }

  configureVideoElementForAutoplay () {
    const videoElement: any = document.getElementById('video');
    videoElement.muted = true;
    videoElement.play();
  }


}
