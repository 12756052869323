export class PartnerRakingList {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  previousPage: boolean;
  nextPage: boolean;
  results: PartnerRakingResult[];

  constructor() {
    this.results = [];
  }
}

export class PartnerRakingResult {
  position: number;
  dn: string;
  category: string;
  dealer: string;
  date: string;
  accumulated: number;
}

export class PartnerRakingFilter {
  userDn: string;
  month: string;
  category: string;
  dn: string;
  page: number;
}
