import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {Subscription} from 'rxjs';
import {EventBusService, Events} from '../../service/event-bus.service';
import {User} from "../../model/user/user";
import {Dealer} from "../../model/coupon/dealer";
import {ContactService} from "../../service/contact.service";

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit, OnDestroy {

  model: User = new User();
  eventPageSingle: Subscription;
  isSinglePage = false;
  modelSubject = null;
  modelMessage = null;

  constructor(public router: Router,
              private eventbus: EventBusService,
              public userService: UserService,
              public contactService: ContactService) {
    super(router);
  }


  ngOnInit(): void {
    this.eventPageSingle = this.eventbus.on(Events.pageSingle, (data) => this.isSinglePage = data);
    this.toggleLoader(false);
    this.userService.user.subscribe({
      next: data => {
        this.model = data as User;
      }, error: err => super.onError(err)
    });
  }

  ngOnDestroy() {
    this.eventPageSingle.unsubscribe();
  }

  onNavScroll(str: string) {
    $('html, body').animate({scrollTop: $('#' + str).offset().top - 105}, 1000);
  }

  /*goToInto(s: string, singlePage: boolean) {
    if (singlePage) { this.isSinglePage = true; }
    this.toggleLoader(true);
    $('html, body').animate({ scrollTop: 0 }, 1000);
    setTimeout(() => {
      this.router.navigate([s]);
    }, 800);
  }*/

  onSend() {
    const arrValidateFields = [
      {value: this.model.name, text: 'Nome*, <br />'},
      {value: this.model.email, text: 'E-mail*, <br />'},
      {value: this.model.phone, text: 'Telefone*, <br />'},
      {value: this.modelSubject, text: 'Assunto*, <br />'},
      {value: this.modelMessage, text: 'Mensagem*, <br />'},
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos obrigatórios: <br><br> ${stringError}`, 'warning');
      return;
    }
    if (!this.isEMailValid(this.model.email)) {
      return;
    }
    const a: any = {
      name: this.model.name,
      email: this.model.email,
      phone: this.model.phone,
      subject: this.modelSubject,
      message: this.modelMessage
    };
    this.contactService.setContact(a).subscribe({
      next: data => {
        this.showMessage('Muito obrigado pelo contato!', `Sua mensagem foi enviada para a nossa equipe, em breve entraremos em contato.`, 'success');
        // this.showMessage('Muito obrigado pelo contato!', `Sua mensagem foi enviada para a nossa equipe. Você receberá sua resposta a partir de 3/1/2022. Boas festas!`, 'success');
        this.modelSubject = null;
        this.modelMessage = null;
        // this.modelDetail.dealership = data as Dealer;
      },
      error: (error) => {
        super.onError(error);
      }
    });
  }

  onClearModel(event: FocusEvent, str: string) {
    setTimeout(() => {
      const a = $(event.target).val();
      if (super.isNullOrUndefined(a)) {
        this.model[str] = '';
      }
    });
  }
}
