export class Item {
  data: string;
  value: number[];
}

export class RankingCategory {
  labels: string[];
  items: Item[];

  constructor() {
    this.items = [];
  }
}
