import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent extends BaseComponent implements OnInit, OnDestroy {


  constructor(public router: Router,
              public service: UserService,
              public route2: ActivatedRoute,) {
    super(router);
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login');
    body.classList.add('background-login');
    this.route2.params.subscribe(data => {
      const resulID = data['id'];
      if (resulID) {
        this.validate(resulID);
      }
    });
  }

  ngOnDestroy(): void {
    $('body').removeClass('login background-login');
  }

  validate(id: string) {
    this.service.validate(id).subscribe({
      next: (data) => {
        localStorage.setItem('token', data['authorizationToken'] as string);
      },
      error: (error) => {
        super.onError(error);
      }
    });
  }

  reset(password: string, confirm: string) {

    if (password.length < 8) {
      super.showMessage('Atenção', 'Sua senha deve conter no mínimo 8 caracteres.', 'warning');
      return;
    }

    if (typeof password === 'undefined' || password.length === 0 || password !== confirm) {
      super.showMessage('Atenção', 'Senhas não conferem!', 'warning');
      return;
    }

    this.service.reset({password: password}).subscribe({
      next: data => {
        localStorage.clear();
        super.showMessage('Sucesso', 'Senha alterada com sucesso, por favor, tente fazer login novamente!', 'success');
        this.router.navigate(['/']);
      }, error: (error) => super.onError(error)
    });
  }
}
