import {Uf} from './state';

export class City {
  id: string;
  name: string;
  uf: Uf;

  constructor() {
    this.uf = new Uf();
  }
}
