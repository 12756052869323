import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Coupon} from '../../model/coupon/coupon';
import {Router} from '@angular/router';
import {EmitEvent, EventBusService, Events} from '../../service/event-bus.service';
import {UserService} from '../../service/user.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  modelDetail: Coupon = new Coupon();
  isPageSingle = false;

  constructor(public router: Router,
              private eventbus: EventBusService,
              public userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
    this.eventbus.emit(new EmitEvent(Events.pageSingle, true));
    setTimeout(() => {
      this.toggleLoader(false);
      this.isPageSingle = true;
    }, 500);
  }

  ngAfterViewInit(): void {
    this.toggleLoader(false);
  }

  ngOnDestroy(): void {
    this.eventbus.emit(new EmitEvent(Events.pageSingle, false));
  }

  goBack() {
    this.isPageSingle = false;
    this.toggleLoader(true);
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000);
  }


}
